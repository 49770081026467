import { ApiRegion, Region } from '@/features/destinations/utils/Region';

export const destinationRegionLabelMapper: { [key in ApiRegion]: Region } = {
  [ApiRegion.Africa]: Region.Africa,
  [ApiRegion.Alaska]: Region.Alaska,
  [ApiRegion.Asia]: Region.Asia,
  [ApiRegion.EasternEurope]: Region.EasternEurope,
  [ApiRegion.AustraliaAndNewZealand]: Region.AustraliaAndNewZealand,
  [ApiRegion.Caribbean]: Region.Caribbean,
  [ApiRegion.CentralAndSouthAmerica]: Region.CentralAndSouthAmerica,
  [ApiRegion.CentralWesternEurope]: Region.CentralWesternEurope,
  [ApiRegion.Hawaii]: Region.Hawaii,
  [ApiRegion.ScandinaviaAndIceland]: Region.ScandinaviaAndIceland,
  [ApiRegion.SouthernEuropeAndMediterranean]:
    Region.SouthernEuropeAndMediterranean,
  [ApiRegion.Mexico]: Region.Mexico,
  [ApiRegion.TahitiAndSouthPacific]: Region.TahitiAndSouthPacific,
  [ApiRegion.UKAndIreland]: Region.UKAndIreland,
  [ApiRegion.USAAndCanada]: Region.USAAndCanada,
  [ApiRegion.Other]: Region.Other,
};
