import { constants } from '@/constants';
import { patchConfirmation } from '@/features/confirmation/api/patchConfirmation';
import type { ModifyAppointmentRequestData } from '@/features/confirmation/utils/ModifyAppointmentRequestData';
import useAppointmentStore from '@/store/AppointmentStore';
import useProgressStore from '@/store/ProgressStore';
import { type QueryObserverResult, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { AgentInfo } from '../utils/AgentInfo';

interface UseValidateSelectedTimeProps {
  isRescheduleAppointment?: boolean;
  refetch: () => Promise<QueryObserverResult<AgentInfo[], Error>>;
  onStart?: () => void;
  onValidTime?: () => void;
  onInvalidTime?: (currentAgent?: AgentInfo) => void;
  onValidForReschedule?: () => void;
  onInvalidForReschedule?: () => void;
  onUpdateAppointmentSuccess?: () => void;
  onUpdateAppointmentError?: () => void;
}

export const useValidateSelectedTime = ({
  isRescheduleAppointment = false,
  refetch,
  onStart,
  onValidTime,
  onInvalidTime,
  onValidForReschedule,
  onInvalidForReschedule,
  onUpdateAppointmentSuccess,
  onUpdateAppointmentError,
}: UseValidateSelectedTimeProps) => {
  const [isValidatingTimeSlot, setIsValidatingTimeSlot] = useState(false);
  const [isValidTime, setIsValidTime] = useState(true);

  const {
    selectedDestination,
    selectedAgent,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    appointmentId,
    setAppointmentId,
    setConfirmedAppointment,
  } = useAppointmentStore();

  const { setProgress } = useProgressStore();

  const navigate = useNavigate();

  const updateAppointmentMutation = useMutation({
    mutationFn: (requestData: ModifyAppointmentRequestData) =>
      patchConfirmation(requestData),
    onSuccess: (data) => {
      onUpdateAppointmentSuccess?.();
      setAppointmentId(data.appointmentId);
      setConfirmedAppointment({
        agentInfo: selectedAgent,
        selectedDate,
        selectedStartTime,
        selectedEndTime,
      });
      setTimeout(() => {
        navigate('/confirmation');
      }, 250);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR) {
        onUpdateAppointmentError?.();
      }
    },
  });

  const validateSelectedTime = async (agent?: AgentInfo) => {
    onStart?.();
    setIsValidatingTimeSlot(true);
    const { data } = await refetch();

    const agentInfo = agent ?? selectedAgent;

    const currentAgent = data?.find(
      (agent) => agent.agentRecordId === agentInfo.agentRecordId,
    );

    const isSelectedTimeAvailable = currentAgent?.timeSlots.some(
      (timeSlot) => timeSlot.startTime === selectedStartTime,
    );

    if (isSelectedTimeAvailable) {
      setIsValidTime(true);
      if (isRescheduleAppointment) {
        onValidForReschedule?.();
        const updateAppointmentData: ModifyAppointmentRequestData = {
          agentRecordId: agentInfo.agentRecordId ?? '',
          agentResourceId: agentInfo.resourceId ?? '',
          appointmentId: appointmentId ?? '',
          serviceTerritoryId: agentInfo.serviceTerritoryId ?? '',
          startTime: selectedStartTime ?? '',
          endTime: selectedEndTime ?? '',
          status: 'Rescheduled',
        };
        updateAppointmentMutation.mutate(updateAppointmentData);
      } else {
        onValidTime?.();
        setProgress(75);
        navigate(
          `/schedule-appointment/${selectedDestination.workTypeId}/contact-info`,
        );
        setIsValidTime(true);
      }
    } else {
      setIsValidTime(false);
      if (isRescheduleAppointment) {
        onInvalidForReschedule?.();
      }
      onInvalidTime?.(currentAgent);
    }
    setIsValidatingTimeSlot(false);
  };

  return {
    validateSelectedTime,
    isValidatingTimeSlot,
    isValidTime,
    isUpdateAppointmentIdle: updateAppointmentMutation.isIdle,
    isUpdateAppointmentSuccess: updateAppointmentMutation.isSuccess,
  };
};
