import { constants } from '@/constants';
import useThemeStore from '@/store/ThemeStore';
import {
  AppHeader,
  Box,
  Button,
  Typography,
} from '@clublabs/shared-component-library';

interface NoAgentsFoundProps {
  onClickTryAgain: () => void;
}

function NoAgentsFound({ onClickTryAgain }: NoAgentsFoundProps) {
  const { isLuxuryTheme } = useThemeStore();
  return (
    <Box
      data-testid='noAgentsFoundContainer'
      sx={{
        backgroundColor: 'background.default',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {!isLuxuryTheme && (
        <AppHeader
          sx={{ position: 'absolute', top: 0 }}
          data-testid='appHeader'
        />
      )}
      <Typography
        variant='h1'
        sx={{
          fontSize: { xs: '40px', md: '60px', lg: '72px' },
          opacity: '23%',
          fontWeight: 700,
          color: (themes) => themes.palette.grey[600],
          height: '290px',
          maxWidth: '800px',
        }}
        data-testid={'errorTitle'}
      >
        All of our advisors are currently unavailable
      </Typography>
      <Box
        sx={{
          '& img': {
            backgroundSize: 'cover',
            position: 'absolute',
            top: '52%',
            left: '52%',
            transform: 'translate(-50%, -50%)',
            width: {
              lg: '354px',
              xs: '300px',
            },
            height: {
              lg: '135px',
              xs: '120px',
            },
            zIndex: 2,
          },
        }}
      >
        <img
          src='/travel/agent/scheduler/noAgentsFoundImage.png'
          aria-label='errorImage'
          alt='errorImage'
        />
      </Box>
      <Typography
        data-testid='errorMessage'
        variant='b1Regular'
        sx={{
          marginBottom: '20px',
          marginTop: '20px',
          color: (themes) => themes.palette.grey[500],
        }}
      >
        Please try again later or call{' '}
        <a
          style={{ color: '#707070' }}
          href={`tel:${constants.SERVICE_DESK_NUMBER}`}
        >
          {constants.SERVICE_DESK_NUMBER}
        </a>
      </Typography>
      <Button
        data-testid='tryAgainButton'
        label='Try again'
        onClick={onClickTryAgain}
      />
    </Box>
  );
}

export default NoAgentsFound;
