import useThemeStore from '@/store/ThemeStore';
import {
  Box,
  Icon,
  Typography,
  useMediaQuery,
} from '@clublabs/shared-component-library';

const NoTimeSlotSelected = () => {
  const { isLuxuryTheme } = useThemeStore();

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Box
      sx={[
        {
          px: 2,
          py: 4,
        },
        !isLuxuryTheme && {
          pt: {
            xs: 0,
            lg: 4,
          },
        },
      ]}
    >
      <Box
        sx={[
          {
            backgroundColor: 'background.default',
            border: '1px solid',
            borderColor: 'grey.300',
            borderRadius: 2,
            px: 4,
            py: 3,
            width: '100%',
          },
          isLuxuryTheme && {
            borderColor: 'primary.main',
          },
        ]}
      >
        <Typography
          variant='h6Medium'
          sx={[
            {
              textAlign: 'left',
              fontSize: { xs: '24px' },
              fontWeight: 700,
              lineHeight: { xs: '36px' },
            },
            isLuxuryTheme && {
              fontFamily: 'Domine',
            },
          ]}
        >
          Please select a date and time to browse available advisors
          <Icon
            iconName={
              isMobileScreen ? 'chevron-down-regular' : 'chevron-right-regular'
            }
            color={isLuxuryTheme ? 'primary' : 'htmlColor'}
            htmlColor='#404040'
            sx={{
              float: 'none',
              ml: 1,
              width: '20px',
              mb: -0.4,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default NoTimeSlotSelected;
