import ProfileImage from '@/components/shared/ProfileImage';
import useThemeStore from '@/store/ThemeStore';
import {
  Box,
  Icon,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import type { AgentInfo } from '../utils/AgentInfo';

type AgentsSelectionProps = {
  agents: AgentInfo[];
  selectedAgent: Partial<AgentInfo> | null;
  onSelectAgent: (agent: AgentInfo) => void;
};

const AgentsSelection: React.FC<AgentsSelectionProps> = ({
  agents,
  selectedAgent,
  onSelectAgent,
}) => {
  const { isLuxuryTheme } = useThemeStore();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        px: 2,
        overflowY: 'scroll',
        scrollbarColor: 'grey transparent',
      }}
    >
      <Stack
        direction='column'
        spacing={2}
        data-testid='agentsSelectionContainer'
        sx={[
          {
            width: '100%',
            my: 4,
          },
          !isLuxuryTheme && {
            mt: {
              xs: 0,
              lg: 4,
            },
          },
        ]}
      >
        {agents.map((agent) => {
          const isSelected =
            selectedAgent?.agentRecordId === agent.agentRecordId;
          const location = agent.location
            ? `${agent.location} Branch`
            : 'Online Only';
          return (
            <Stack
              direction='row'
              key={agent.agentRecordId}
              sx={[
                {
                  backgroundColor: 'background.default',
                  border: '1px solid',
                  borderColor: 'grey.300',
                  borderRadius: 2,
                  padding: 2,
                  width: '100%',
                  alignItems: 'center',
                  cursor: 'pointer',
                },
                isLuxuryTheme && {
                  borderColor: 'primary.main',
                  borderWidth: '2px',
                },
                isSelected && {
                  backgroundColor: '#FCFBF7',
                  borderColor: 'primary.main',
                  borderWidth: '2px',
                },
              ]}
              onClick={() => onSelectAgent(agent)}
            >
              <ProfileImage src={agent.image} alt={agent.name} size='small' />
              <Stack
                direction='column'
                sx={{
                  ml: 2,
                  flex: 1,
                }}
              >
                <Typography
                  variant='h6Medium'
                  sx={[
                    {
                      textAlign: 'left',
                    },
                    isSelected && {
                      color: isLuxuryTheme ? 'secondary.main' : 'inherit',
                    },
                    isLuxuryTheme && {
                      fontFamily: 'Domine',
                    },
                  ]}
                >
                  {agent.name}
                </Typography>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Icon
                    iconName={
                      isLuxuryTheme
                        ? 'location-dot-regular'
                        : 'location-dot-duotone'
                    }
                    color='primary'
                  />
                  <Typography
                    variant='body1'
                    sx={[
                      {
                        textAlign: 'left',
                      },
                      isSelected && {
                        color: isLuxuryTheme ? 'secondary.main' : 'inherit',
                      },
                    ]}
                  >
                    {location}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default AgentsSelection;
