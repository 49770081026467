import type { SessionSource } from '@/store/SessionStore';
import useThemeStore from '@/store/ThemeStore';
import { useCallback, useMemo } from 'react';
import type { ActivityType, TagParams } from './types';
import { deriveFlowTag, getClubCode, loadUtagJs } from './utils';

const useTagging = () => {
  const { isLuxuryTheme } = useThemeStore();

  const clubcode = useMemo(() => getClubCode(), []);

  const commonTags = useMemo(
    () => ({
      channel: 'travel',
      clubcode,
      subchannel1: isLuxuryTheme ? 'luxury scheduler' : 'scheduler',
    }),
    [clubcode, isLuxuryTheme],
  );

  const maxRetryCount = 5;

  const tag = useCallback(
    async (
      activityType: ActivityType,
      sessionSource: SessionSource,
      tags: TagParams,
      retryCount = 0,
    ) => {
      if (retryCount > maxRetryCount) {
        return;
      }

      const lowercaseDestination = (tags.trv_end_location || '').toLowerCase();
      const lowercaseCommPref = tags.comm_pref?.toLowerCase();

      let page = tags.page;
      if (isLuxuryTheme) {
        page = page.replace('travel:scheduler', 'travel:luxury scheduler');
      }

      window.aceMediaTagValues = {
        ...commonTags,
        ...tags,
        ...(lowercaseCommPref ? { comm_pref: lowercaseCommPref } : {}),
        page,
        trv_end_location: lowercaseDestination,
        flow: deriveFlowTag(sessionSource, tags.flow),
      };

      if (!window.utag) {
        loadUtagJs();
      } else {
        window.utag[activityType](window.aceMediaTagValues);
      }
    },
    [commonTags, isLuxuryTheme],
  );

  return { tag };
};

export default useTagging;
