import {
  Button,
  Link as LinkScl,
  Modal,
  ModalCloseIconButton,
  ModalContent,
  ModalTitle,
} from '@clublabs/shared-component-library';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';

import './Confirmation.css';

import ErrorPage from '@/components/shared/ErrorPage';
import { constants } from '@/constants';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { queryClient } from '@/lib/queryClient';
import { Page } from '@/lib/tagging';
import useTagging from '@/lib/tagging/useTagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useProgressStore from '@/store/ProgressStore';
import useSessionStore from '@/store/SessionStore';
import useThemeStore from '@/store/ThemeStore';
import { atcb_action } from 'add-to-calendar-button-react';
import { patchCancel } from '../api/patchCancel';
import AppointmentInformation from '../components/AppointmentInformation';
import CancelAppointmentModalBody from '../components/CancelAppointmentModalBody';
import type { Confirmation as ConfirmationInterface } from '../utils/Confirmation';
import type { ModifyAppointmentRequestData } from '../utils/ModifyAppointmentRequestData';
import { getEmailOption } from '../utils/getEmailOption';

interface EmailEvent {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  location: string;
  options: Array<
    | 'Google'
    | 'Apple'
    | 'iCal'
    | 'Microsoft365'
    | 'MicrosoftTeams'
    | 'Outlook.com'
    | 'Yahoo'
  >;
  timeZone: string;
  useUserTZ?: boolean;
}

function Confirmation() {
  useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !nextLocation.pathname.includes('reschedule-appointment') &&
      !nextLocation.pathname.includes('cancel-appointment'),
  );
  useLandingPageRedirection();

  const [isCancellingAppointment, setIsCancellingAppointment] = useState(false);

  const {
    appointmentId,
    confirmationId,
    customerId,
    contactInfo,
    selectedDestination,
    confirmedAppointment,
    setShouldUseDefaultWorktype,
    shouldUseDefaultWorktype,
    destinations,
  } = useAppointmentStore();

  const { sessionId, sessionSource, flowType, setFlowType } = useSessionStore();
  const location = useLocation();
  const { setFrom } = useProgressStore();

  const luxuryDestination = useMemo(
    () =>
      destinations.find(
        (destination) => destination.name?.toLowerCase() === 'luxury',
      ),
    [destinations],
  );

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [appointmentCancelledFailed, setAppointmentCancelledFailed] =
    useState(false);
  const handleCloseModal = () => {
    setOpenCancelModal(false);
  };

  const navigate = useNavigate();

  const { isLuxuryTheme } = useThemeStore();

  const tagging = useTagging();

  const cancelAppointment = useMutation({
    mutationFn: (cancelAppointmentData: ModifyAppointmentRequestData) =>
      patchCancel(cancelAppointmentData),
    onSuccess: (data: ConfirmationInterface) => {
      setIsCancellingAppointment(false);
      navigate(`/cancel-appointment/${data.appointmentId}`);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR)
        setAppointmentCancelledFailed(true);
    },
  });

  const cancelAppointmentHandler = () => {
    setIsCancellingAppointment(true);
    setFlowType('cancel');
    const cancelAppointmentData: ModifyAppointmentRequestData = {
      agentResourceId: confirmedAppointment?.agentInfo?.resourceId ?? '',
      appointmentId: appointmentId ?? '',
      status: 'Canceled',
    };
    cancelAppointment.mutate(cancelAppointmentData);
  };

  useEffect(() => {
    const confirmationTags = {
      category: ['travel:scheduler'],
      confirmation_id: confirmationId,
      product: ['appointment'],
      quantity: ['1'],
      custID: customerId,
    };

    // if the user lands on the confirmation page through the email link, don't fire the confirmation tags OR
    // if they end up modifying the appointment even through email, we want to fire the confirmation tags
    const shouldPopulateConfirmationTags =
      sessionSource !== 'email' ||
      (sessionSource === 'email' && flowType === 'modify');

    tagging.tag('view', sessionSource, {
      page: Page.confirmation,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination.name,
      comm_pref: contactInfo.communicationPreference,
      // for reporting reasons, we don't want these confirmation tags to be added if the source is email
      ...(shouldPopulateConfirmationTags ? confirmationTags : {}),
    });
  }, [
    confirmationId,
    contactInfo.communicationPreference,
    customerId,
    flowType,
    selectedDestination.name,
    sessionSource,
    sessionId,
    tagging.tag,
  ]);

  const clickAddToCalendarHandler = () => {
    const selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const baseDate = dayjs(
      confirmedAppointment?.selectedDate ||
        // biome-ignore lint/style/noNonNullAssertion: assuming we're going to always have a selectedStartTime
        new Date(confirmedAppointment?.selectedStartTime!),
    );

    const emailEvent: EmailEvent = {
      name: `Your appointment with ${confirmedAppointment?.agentInfo?.name}`,
      description: `We look forward to our ${selectedDestination?.name} call on ${baseDate.format('MMMM D')} at ${dayjs(confirmedAppointment?.selectedStartTime).format('h:mma')}!`,
      startDate: baseDate.format('YYYY-MM-DD'),
      startTime: dayjs(confirmedAppointment?.selectedStartTime).format('HH:mm'),
      endDate: dayjs(confirmedAppointment?.selectedEndTime).format(
        'YYYY-MM-DD',
      ),
      endTime: dayjs(confirmedAppointment?.selectedEndTime).format('HH:mm'),
      location: 'Online',
      useUserTZ: false,
      options: [contactInfo.email ? getEmailOption(contactInfo.email) : 'iCal'],
      timeZone: selectedTimezone,
    };

    atcb_action(emailEvent);
  };

  const handleModifyAppointment = () => {
    setFrom(location.pathname);
    const workTypeId = selectedDestination.workTypeId;

    queryClient.resetQueries({
      queryKey: ['timeSlots', workTypeId],
    });
    if (shouldUseDefaultWorktype) {
      queryClient.resetQueries({
        queryKey: ['timeSlots', luxuryDestination?.workTypeId],
      });
      setShouldUseDefaultWorktype(false);
    }
    navigate(`/reschedule-appointment/${appointmentId}`);
  };

  if (appointmentCancelledFailed) {
    return (
      <ErrorPage
        onClickTryAgain={() => {
          window.location.replace(`${constants.APP_BASE_URL}`);
        }}
      />
    );
  }

  return (
    <>
      <AppointmentInformation
        onClickModifyAppointment={handleModifyAppointment}
        onClickCancelAppointment={() => setOpenCancelModal(true)}
        buttonGroup={{
          'data-testid': 'addToCalendarOrCloseButtonGroup',
          children: (
            <>
              <Button
                label='Add to calendar'
                onClick={clickAddToCalendarHandler}
                fullWidth
                variant='outlined'
                sx={{
                  backgroundColor: (theme) => theme.palette.common.white,
                }}
              />
              <LinkScl
                href={constants.TRAVEL_HOME_PAGE_URL}
                sx={{ width: '100%' }}
                data-testid='closePageButtonLink'
              >
                <Button label='Close page' fullWidth />
              </LinkScl>
            </>
          ),
        }}
      />
      <Modal
        open={openCancelModal}
        onClose={handleCloseModal}
        size='medium'
        sx={{
          '& .MuiPaper-root': {
            padding: 0,
          },
          '& .MuiDialog-paper': {
            minHeight: 'auto',
          },
        }}
        data-testid={'modal'}
      >
        <ModalCloseIconButton aria-label='Close' onClick={handleCloseModal} />
        <ModalTitle
          id='modal-title-1'
          sx={[
            { padding: '1.5rem', marginBottom: 0, pr: '3rem' },
            isLuxuryTheme && { color: 'secondary.400' },
          ]}
        >
          Are you sure you want to cancel your appointment?
        </ModalTitle>
        <ModalContent id='modal-content-1'>
          <CancelAppointmentModalBody
            onClickClose={handleCloseModal}
            onClickCancel={cancelAppointmentHandler}
            isCancellingAppointment={isCancellingAppointment}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

export default Confirmation;
