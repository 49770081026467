import ContactInfoForm, {
  type ContactInfoFields,
} from '@/components/shared/ContactInfoForm';
import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import ProfileImage from '@/components/shared/ProfileImage';
import SplitPageLayout from '@/components/shared/SplitPageLayout';
import { useAppointmentCreation } from '@/features/confirmation/api/useAppointmentCreation';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page } from '@/lib/tagging';
import useTagging from '@/lib/tagging/useTagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import useThemeStore from '@/store/ThemeStore';
import {
  Box,
  Icon,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AgentInfoContactInfoPage from '../components/AgentInfoContactInfoPage';

const ContactInfoLuxury = () => {
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedAgent,
    selectedDestination,
    contactInfo,
    setContactInfo,
  } = useAppointmentStore();

  const { sessionSource, sessionId, flowType } = useSessionStore();

  const { isLuxuryTheme } = useThemeStore();

  const tagging = useTagging();

  const redirectPage = useLandingPageRedirection();

  const navigate = useNavigate();

  const regularFields: ContactInfoFields[] = [
    'firstName',
    'lastName',
    'zipCode',
    'communicationPreference',
    'phoneNumber',
    'email',
    'expectedTravelTime',
    'typeOfTrip',
    'additionalInformation',
  ];

  const luxuryFields: ContactInfoFields[] = [
    'firstName',
    'lastName',
    'zipCode',
    'communicationPreference',
    'phoneNumber',
    'email',
    'additionalInformation',
  ];

  const handleIsCreatingAppointment = useCallback(() => {
    setIsCreatingAppointment(true);
  }, []);

  const handleError = useCallback(() => {
    setIsError(true);
    setIsCreatingAppointment(false);
  }, []);

  useEffect(() => {
    tagging.tag('view', sessionSource, {
      page: Page.contactInfo,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination.name,
    });
  }, [
    tagging.tag,
    sessionSource,
    sessionId,
    flowType,
    selectedDestination.name,
  ]);

  const {
    handleStartAppointmentCreation,
    isProcessCustomerAccountSuccess,
    isCreateAppointmentSuccess,
  } = useAppointmentCreation({
    onStart: handleIsCreatingAppointment,
    onProcessCustomerAccountError: handleError,
    onCreateAppointmentError: handleError,
  });

  if (isError) {
    return <ErrorPage onClickTryAgain={() => navigate(redirectPage)} />;
  }

  if (isCreatingAppointment) {
    const radiosInfo = [
      {
        radioMessage: 'Gathering your information',
        isChecked: isProcessCustomerAccountSuccess,
      },
      {
        radioMessage: 'Submitting your appointment',
        isChecked: isCreateAppointmentSuccess,
      },
    ];

    return (
      <LoadingContainer
        message='It may take up to 30 seconds to schedule your appointment...'
        radiosInfo={radiosInfo}
      />
    );
  }

  return (
    <SplitPageLayout
      leftSide={
        <Stack
          direction='column'
          spacing={isLuxuryTheme ? 4 : 3}
          sx={[
            {
              px: 2,
              justifyContent: 'center',
              height: '100%',
            },
            isLuxuryTheme && {
              mt: {
                xs: 4,
                lg: 0,
              },
            },
          ]}
        >
          <Stack
            direction={
              isLuxuryTheme
                ? 'column'
                : {
                    lg: 'column',
                    xs: 'row',
                  }
            }
            spacing={
              isLuxuryTheme
                ? 0
                : {
                    lg: 0,
                    xs: 1.25,
                  }
            }
            sx={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <ProfileImage
              src={selectedAgent.image || ''}
              alt={selectedAgent.name || ''}
              size={isLuxuryTheme ? 'big' : 'responsive'}
            />
            <Stack
              direction='column'
              sx={{
                mt: 1.25,
              }}
            >
              <Typography
                variant='h4'
                sx={[
                  {
                    fontSize: '24px',
                    textAlign: {
                      xs: 'left',
                      lg: 'center',
                    },
                  },
                  isLuxuryTheme && {
                    fontSize: '27px',
                  },
                ]}
              >
                {selectedAgent.name || ''}
              </Typography>
              <Stack
                direction='row'
                spacing={1}
                justifyContent={isLuxuryTheme ? 'center' : { lg: 'center' }}
                alignItems='center'
              >
                <Icon
                  iconName={
                    isLuxuryTheme
                      ? 'location-dot-regular'
                      : 'location-dot-duotone'
                  }
                  color='primary'
                />
                <Typography
                  variant='body1'
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  {selectedAgent.location || 'Online Only'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <AgentInfoContactInfoPage
            agentName={selectedAgent.name || ''}
            selectedDate={selectedDate}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            selectedDestination={selectedDestination}
          />
        </Stack>
      }
      rightSide={
        <Box
          sx={[
            {
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
            isLuxuryTheme && {
              mt: 6,
            },
          ]}
        >
          <ContactInfoForm
            formHeader={`How should ${selectedAgent.name?.split(' ')[0]} contact you?`}
            fields={isLuxuryTheme ? luxuryFields : regularFields}
            formValues={contactInfo}
            updateField={setContactInfo}
            onSubmit={handleStartAppointmentCreation}
          />
        </Box>
      }
    />
  );
};

export default ContactInfoLuxury;
