import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import type { AgentInfo } from '@/features/appointments/utils/AgentInfo';
import { useQuery } from '@tanstack/react-query';

const getAgentInfoAndAppointments = async (
  workTypeId: string,
  clubCode: string,
  isLuxury?: boolean,
): Promise<AgentInfo[]> => {
  const url = isLuxury
    ? getEndpoints().timeSlots.replace('{workTypeId}', workTypeId)
    : getEndpoints()
        .timeSlotsByClub.replace('{workTypeId}', workTypeId)
        .replace('{clubCode}', clubCode);
  const config = { method: 'GET' };

  return await apiWrapper<AgentInfo[]>(url, config);
};

export const useAppointmentsQuery = (
  workTypeId: string,
  clubCode: string,
  options?: { enabled: boolean },
  isLuxury?: boolean,
) => {
  return useQuery({
    queryKey: ['timeSlots', workTypeId],
    queryFn: () => getAgentInfoAndAppointments(workTypeId, clubCode, isLuxury),
    enabled: options?.enabled ?? true,
  });
};
