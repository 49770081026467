import { getEndpoints } from '@/endpoints';
import { useQuery } from '@tanstack/react-query';

import { apiWrapper } from '@/apiWrapper';
import type { Destination } from '@/features/destinations/utils/Destination';

const getDestinations = async (isLuxury?: boolean): Promise<Destination[]> => {
  const url = isLuxury
    ? getEndpoints().luxuryDestinations
    : getEndpoints().destinations;
  const config = { method: 'GET' };

  return await apiWrapper<Destination[]>(url, config);
};

export const useDestinations = (isLuxury?: boolean) => {
  return useQuery({
    queryKey: ['destinations'],
    queryFn: () => getDestinations(isLuxury),
    staleTime: Number.POSITIVE_INFINITY,
  });
};
