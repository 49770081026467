import type { Destination } from '@/features/destinations/utils/Destination';
import useThemeStore from '@/store/ThemeStore';
import {
  Card,
  Icon,
  Stack,
  Typography,
  type TypographyProps,
} from '@clublabs/shared-component-library';
import dayjs from 'dayjs';

interface AgentInfoConfirmationCardProps {
  agentName: string;
  selectedDate?: Date;
  selectedStartTime?: string;
  selectedEndTime?: string;
  selectedDestination?: Destination;
}

function AgentInfoContactInfoPage({
  agentName,
  selectedDate,
  selectedStartTime,
  selectedEndTime,
  selectedDestination,
}: AgentInfoConfirmationCardProps) {
  const { isLuxuryTheme } = useThemeStore();

  const labelsStyles: TypographyProps['sx'] = [
    { margin: 0, padding: 0, textAlign: 'left' },
    isLuxuryTheme && { fontSize: '20px' },
  ];

  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      data-testid='callInfoCard'
      sx={{
        width: '100%',
        textAlign: 'left',
      }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Card
        sx={[
          {
            marginBottom: '16px',
            padding: '1.5rem 1rem 1rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: '20px',
          },
          isLuxuryTheme && {
            backgroundColor: 'background.default',
            border: '1px solid',
            borderColor: 'primary.main',
          },
        ]}
      >
        <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
          <Icon
            iconName='calendar-check-duotone'
            color='primary'
            sx={{ width: '35px', height: '35px' }}
          />
          <Typography
            sx={[
              {
                fontWeight: '700',
                fontSize: { xs: '16px', lg: '24px' },
              },
              isLuxuryTheme && {
                fontSize: {
                  xs: '27px',
                },
                fontFamily: 'Domine',
              },
            ]}
          >
            30-minute call with {agentName.split(' ')[0]}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'baseline'}
          gap={'5px'}
          data-testid='dateAndTimeInfo'
        >
          <Typography variant='h6Medium' sx={labelsStyles}>
            When:
          </Typography>
          <Typography variant='b1Regular' sx={labelsStyles}>
            {dayjs(selectedDate).format('dddd, MMMM D')}
            {' from '}
            {selectedStartTime &&
              selectedEndTime &&
              `${dayjs(new Date(selectedStartTime)).format('hh:mma')} - ${dayjs(
                new Date(selectedEndTime),
              ).format('hh:mma')}`}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'baseline'}
          gap={'5px'}
          data-testid='destinationInfo'
        >
          <Typography variant='h6Medium' sx={labelsStyles}>
            Destination:
          </Typography>
          <Typography variant='b1Regular' sx={labelsStyles}>
            {selectedDestination?.name}
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
}

export default AgentInfoContactInfoPage;
