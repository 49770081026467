import {
  Box,
  Button,
  LinearProgress,
  Stack,
} from '@clublabs/shared-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import useProgressStore from '../../store/ProgressStore';
import './ProgressBar.css';
import useThemeStore from '@/store/ThemeStore';

interface ProgressBarProps {
  to?: string;
  buttonDisabled?: boolean;
  fixed?: boolean;
  nextProgressValue?: number;
  displayBackButton: boolean;
  isInsideForm?: boolean;
  onClickContinue?: () => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  to,
  buttonDisabled,
  fixed,
  nextProgressValue,
  displayBackButton = false,
  isInsideForm = false,
  onClickContinue = () => {},
}: ProgressBarProps) => {
  const { progress, showProgress, setProgress, setFrom } = useProgressStore();

  const navigate = useNavigate();
  const location = useLocation();

  const { isLuxuryTheme } = useThemeStore();

  const updateProgress = () => {
    setFrom(location.pathname);
    onClickContinue();
    if (nextProgressValue) setProgress(nextProgressValue);
    if (to) navigate(to);
  };

  const goBackProgress = () => {
    setFrom(location.pathname);
    navigate(-1);
  };

  return (
    <Box
      data-testid='progressBarContainer'
      sx={[
        {
          display: 'flex',
          width: '100%',
          backgroundColor: 'white',
          padding: '10px',
          justifyContent: 'center',
        },
        !!fixed && {
          position: 'fixed',
          bottom: 0,
        },
        !showProgress && {
          display: 'none',
        },
      ]}
    >
      <Stack
        direction='row'
        spacing={3}
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {displayBackButton && (
          <Button
            data-testid='backButton'
            label='Back'
            onClick={goBackProgress}
            variant='text'
            sx={{
              width: {
                xs: '60px',
                sm: '88px',
              },
            }}
          />
        )}
        <LinearProgress
          data-testid='linearProgressBar'
          value={progress}
          variant='determinate'
          aria-label='progress'
          completeIndicator={true}
          aria-valuenow={progress}
          slotProps={{
            root: {
              sx: [
                {
                  width: '60%',
                  maxWidth: '30rem',
                },
                displayBackButton && {
                  width: {
                    xs: '50%',
                    md: '45%',
                  },
                },
              ],
            },
          }}
          completeIndicatorProps={{
            sx: {
              '& svg': {
                borderRadius: '50%',
                display: 'block',
                backgroundColor: (theme) => theme.palette.grey[200],
              },
            },
            completeIconProps: { sx: { display: 'none' } },
          }}
        />
        <Button
          data-testid='nextButton'
          label='Next'
          disabled={buttonDisabled}
          onClick={updateProgress}
          type={isInsideForm ? 'submit' : 'button'}
          color={isLuxuryTheme ? 'secondary' : 'primary'}
          sx={{
            width: {
              xs: '60px',
              sm: '88px',
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default ProgressBar;
