import { LogCannon } from '@clublabs/log-cannon';

interface LogMessage {
  message:
    | {
        [key: string]: unknown;
      }
    | string;
  level: string;
  utcTimeStamp: number;
}

export interface LogRequestParams {
  message: string;
  level: string;
  data: {
    [key: string]: unknown;
  };
}

export interface LogResponse {
  [key: string]: unknown;
}

interface LogObject {
  response?: LogResponse;
  [key: string]: unknown;
}

export const blastDataToLogCannon = (logObject: LogObject) => {
  try {
    const logBrowserData: LogRequestParams = {
      message: '',
      level: 'info',
      data: {
        log: 'TRVL-BROWSER-API-RESULT',
        'user-agent': navigator.userAgent,
        ...logObject,
        'confirmation-id': logObject.response?.confirmationId
          ? logObject.response?.confirmationId
          : 'N/A',
      },
    };

    const logMessage: LogMessage = {
      level: logBrowserData.level,
      message: logBrowserData.data,
      utcTimeStamp: new Date().getTime(),
    };

    LogCannon.blast('trvl-browser-log', logMessage);
  } catch {}
};
