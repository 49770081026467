import ProfileImage from '@/components/shared/ProfileImage';
import useAppointmentStore from '@/store/AppointmentStore';
import useThemeStore from '@/store/ThemeStore';
import {
  AppHeader,
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Icon,
  type IconProps,
  Stack,
  Typography,
  type TypographyProps,
} from '@clublabs/shared-component-library';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface AppointmentInformationProps {
  isCancelled?: boolean;
  buttonGroup: {
    'data-testid': string;
    children: React.ReactNode;
  };
  onClickModifyAppointment?: () => void;
  onClickCancelAppointment?: () => void;
}

const AppointmentInformation: React.FC<AppointmentInformationProps> = ({
  isCancelled,
  buttonGroup,
  onClickModifyAppointment,
  onClickCancelAppointment,
}) => {
  const { isLuxuryTheme } = useThemeStore();

  const { confirmedAppointment, selectedDestination } = useAppointmentStore();

  const [searchParams] = useSearchParams();

  const emailFromParams = searchParams.get('source');

  const labelsStyles: TypographyProps['sx'] = {
    textAlign: 'left',
    fontSize: { xs: '20px' },
    lineHeight: '32px',
    '& span': {
      fontWeight: 400,
    },
  };

  const calendarIcon = useMemo((): IconProps['iconName'] => {
    if (isCancelled) {
      return isLuxuryTheme
        ? 'calendar-circle-exclamation-regular'
        : 'calendar-circle-exclamation-duotone';
    }
    return isLuxuryTheme ? 'calendar-check-regular' : 'calendar-check-duotone';
  }, [isCancelled, isLuxuryTheme]);

  return (
    <Box
      sx={[
        {
          backgroundColor: '#F7F8FA',
          minHeight: '100vh',
          display: 'flex',
        },
        isLuxuryTheme && {
          backgroundImage:
            'url(/travel/agent/scheduler/luxuryLeftSideBackground.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      ]}
    >
      <Stack
        direction='column'
        spacing={2}
        sx={[
          {
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            pb: 2,
          },
          isLuxuryTheme && { pt: 2 },
        ]}
      >
        {!isLuxuryTheme && <AppHeader data-testid='appHeader' />}
        <ProfileImage
          src={confirmedAppointment?.agentInfo?.image}
          alt='Profile Image'
          size='big'
          testId='selectedAgentImage'
        />
        <Typography
          variant='h4'
          data-testid='agentMessage'
          sx={{
            fontSize: '27px',
            px: 2,
            maxWidth: '836px',
          }}
        >
          {isCancelled
            ? `Your appointment has been cancelled. We look forward to helping you plan your next vacation whenever you're ready.`
            : 'Looking forward to our call!'}
        </Typography>
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack
            direction='column'
            spacing={2}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '512px',
            }}
          >
            <Card
              data-testid='callInfoCard'
              sx={[
                {
                  py: '24px',
                  width: '100%',
                  px: 0,
                },
                isLuxuryTheme && {
                  backgroundColor: 'background.default',
                  border: '1px solid',
                  borderColor: 'primary.main',
                },
              ]}
            >
              <Stack direction='column' spacing={3}>
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={2}
                  sx={{
                    px: 2,
                  }}
                >
                  <Icon
                    iconName={calendarIcon}
                    color={isCancelled && !isLuxuryTheme ? 'error' : 'primary'}
                    sx={{
                      width: '47px',
                      height: '47px',
                    }}
                  />
                  <Typography
                    sx={[
                      {
                        fontWeight: '700',
                        fontSize: { xs: '27px' },
                        textAlign: 'left',
                        lineHeight: '32px',
                      },
                      isLuxuryTheme && {
                        fontFamily: 'Domine',
                      },
                    ]}
                    data-testid='cardHeader'
                  >
                    30-minute call with{' '}
                    {confirmedAppointment?.agentInfo?.name?.split(' ')[0]}
                  </Typography>
                </Stack>
                <Stack
                  direction='column'
                  spacing={2}
                  sx={{
                    pl: 3,
                    pr: '18px',
                  }}
                >
                  <Typography
                    variant='h6Medium'
                    data-testid='dateAndTimeInfo'
                    sx={labelsStyles}
                  >
                    When:{' '}
                    <span>
                      {confirmedAppointment?.selectedDate
                        ? dayjs(confirmedAppointment?.selectedDate).format(
                            'dddd, MMMM D',
                          )
                        : dayjs(
                            new Date(
                              confirmedAppointment?.selectedStartTime as string,
                            ),
                          ).format('dddd, MMMM D')}{' '}
                      from{' '}
                      {confirmedAppointment?.selectedStartTime &&
                        confirmedAppointment?.selectedEndTime &&
                        `${dayjs(new Date(confirmedAppointment?.selectedStartTime)).format('hh:mma')} - ${dayjs(new Date(confirmedAppointment?.selectedEndTime)).format('hh:mma')}`}
                    </span>
                  </Typography>
                  <Typography
                    variant='h6Medium'
                    data-testid='destinationInfo'
                    sx={labelsStyles}
                  >
                    Destination: <span>{selectedDestination?.name}</span>
                  </Typography>
                  {emailFromParams !== 'email' && (
                    <Typography
                      variant='h6Medium'
                      data-testid='nextStepsMessage'
                      sx={labelsStyles}
                    >
                      Next steps:{' '}
                      <span>
                        {isCancelled
                          ? `Schedule a new appointment when you're ready to plan a trip.`
                          : 'Keep an eye out for an email confirmation from me.'}
                      </span>
                    </Typography>
                  )}

                  {!isCancelled && (
                    <Stack
                      direction='row'
                      spacing={3}
                      sx={{
                        alignItems: 'center',
                      }}
                      data-testid='modifyOrCancelButtonGroup'
                    >
                      <Button
                        data-testid='modifyAppointmentButton'
                        variant='text'
                        label='Modify appointment'
                        sx={[
                          {
                            textDecoration: 'underline',
                            fontSize: '18px',
                            fontWeight: 400,
                            p: 0,
                            color: 'primary.400',
                          },
                          isLuxuryTheme && { color: 'primary.main' },
                        ]}
                        onClick={onClickModifyAppointment}
                      />
                      <Divider
                        orientation='vertical'
                        sx={{
                          height: '25px',
                        }}
                      />
                      <Button
                        variant='text'
                        label='Cancel appointment'
                        data-testid='cancelAppointmentButton'
                        sx={[
                          {
                            textDecoration: 'underline',
                            fontSize: '18px',
                            fontWeight: 400,
                            p: 0,
                            color: 'primary.400',
                          },
                          isLuxuryTheme && { color: 'primary.main' },
                        ]}
                        onClick={onClickCancelAppointment}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Card>
            <ButtonGroup
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px !important',
                pt: '8px',
              }}
              data-testid={buttonGroup['data-testid']}
            >
              {buttonGroup.children}
            </ButtonGroup>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
};

export default AppointmentInformation;
