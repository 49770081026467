import { createTheme, themes } from '@clublabs/shared-component-library';
import type { Components, Theme, ThemeOptions } from '@mui/material';
import '@mui/x-date-pickers/themeAugmentation';
import * as colors from './colors';

import '@fontsource/domine/400.css';
import '@fontsource/domine/500.css';
import '@fontsource/domine/600.css';
import '@fontsource/domine/700.css';

const defaultTheme = themes.light;

export const lightTheme = createTheme(defaultTheme, {
  palette: {
    background: {
      default: '#F7F5F1',
    },
  },
} satisfies ThemeOptions);

export const luxuryTheme = createTheme(defaultTheme, {
  palette: {
    mode: 'dark',
    primary: {
      100: colors.primary[100],
      200: colors.primary[200],
      main: colors.primary[300],
      300: colors.primary[300],
      400: colors.primary[400],
      focusIndicator: `2px solid ${colors.primary[400]}`,
    },
    secondary: {
      100: colors.secondary[100],
      200: colors.secondary[200],
      main: colors.secondary[300],
      300: colors.secondary[300],
      400: colors.secondary[400],
    },
    grey: {
      600: '#ffffff',
    },
    background: {
      default: '#222222',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: ({ theme, ownerState: { color } }) => {
          const col = !color || color === 'inherit' ? 'primary' : color;
          return {
            ...getOriginalOverride(theme, 'MuiButton', 'contained'),
            border: `1px solid ${theme.palette[col].main}`,
            color: 'white',
            '&:hover': {
              background: theme.palette[col][400],
              borderColor: theme.palette[col][400],
            },
            '&.Mui-disabled': {
              background: theme.palette.grey[200],
            },
          };
        },
        text: ({ theme, ownerState: { color } }) => {
          const col = !color || color === 'inherit' ? 'primary' : color;
          return {
            ...getOriginalOverride(theme, 'MuiButton', 'text'),
            color: theme.palette.secondary[400],
            '&:hover': {
              background: theme.palette[col][100],
            },
          };
        },
      },
    },
    MuiTypography: {
      variants: [
        ...(defaultTheme.components?.MuiTypography?.variants || []),
        {
          props: { variant: 'b1Medium' },
          style: {
            fontFamily: 'Domine',
          },
        },
        {
          props: { variant: 'h5Medium' },
          style: {
            fontFamily: 'Domine',
          },
        },
      ],
      styleOverrides: {
        h3: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiTypography', 'h3'),
          fontFamily: 'Domine',
          fontWeight: 600,
        }),
        h4: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiTypography', 'h4'),
          fontFamily: 'Domine',
          fontWeight: 600,
        }),
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          color: '#404040 !important',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiDayCalendar', 'weekDayLabel'),
          color: '#404040',
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiMenuItem', 'root'),
          '& .MuiMenuItemTypography-regular': {
            color: theme.palette.secondary[400],
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiSelect', 'root'),
          '& .MuiSelect-value': {
            color: theme.palette.secondary[400],
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiFormHelperText', 'root'),
          '& .MuiTextFieldHelperMessage': {
            color: theme.palette.grey[300],
          },
        }),
      },
    },
    MuiStack: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...getOriginalOverride(theme, 'MuiStack', 'root'),
          '& .MuiRadio-LabelStack': {
            color: theme.palette.grey[600],
          },
        }),
      },
    },
  },
} satisfies ThemeOptions);

type StyleRuleFunction = ({
  theme,
}: { theme: Omit<Theme, 'components'> }) => Record<string, unknown>;
type StyleRule = Record<string, unknown> | StyleRuleFunction;

const getOriginalOverride = (
  theme: Theme,
  component: keyof Components,
  styleRule: string,
) => {
  const defaultComponent = defaultTheme.components?.[component] as {
    styleOverrides?: Record<string, StyleRule>;
  };
  if (!defaultComponent) return;

  const defaultStyle = defaultComponent.styleOverrides?.[styleRule];
  if (!defaultStyle) return;

  if (typeof defaultStyle === 'function') {
    return defaultStyle({ theme });
  }
  return defaultStyle;
};
