import { create } from 'zustand';

type Theme = 'regular' | 'luxury';

export interface ThemeState {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isLuxuryTheme: boolean;
}

const useThemeStore = create<ThemeState>((set) => ({
  theme: 'regular',
  setTheme: (theme) => set({ theme, isLuxuryTheme: theme === 'luxury' }),
  isLuxuryTheme: false,
}));

export default useThemeStore;
