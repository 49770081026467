interface Color {
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
}

export const primary: Color = {
  100: '#ebcfaf',
  200: '#d1b08e',
  300: '#B5926B',
  400: '#a07b4f',
};

export const secondary: Color = {
  100: '#7f7f7f',
  200: '#5f5f5f',
  300: '#404040',
  400: '#1f1f1f',
};
