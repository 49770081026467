import { constants } from '@/constants';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page } from '@/lib/tagging';
import useTagging from '@/lib/tagging/useTagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import { Button, Link as LinkScl } from '@clublabs/shared-component-library';
import { useEffect } from 'react';
import { Link, useBlocker } from 'react-router-dom';
import AppointmentInformation from './AppointmentInformation';

function AppointmentCancelled() {
  useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );
  const navigateTo = useLandingPageRedirection();
  const { selectedDestination } = useAppointmentStore();

  const { sessionSource, sessionId, flowType } = useSessionStore();

  const tagging = useTagging();

  useEffect(() => {
    tagging.tag('view', sessionSource, {
      page: Page.cancelAppointment,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination.name,
    });
  }, [
    selectedDestination.name,
    sessionSource,
    sessionId,
    flowType,
    tagging.tag,
  ]);

  return (
    <AppointmentInformation
      isCancelled
      buttonGroup={{
        'data-testid': 'scheduleNewDateOrCloseButtonGroup',
        children: (
          <>
            <Link
              to={{ pathname: navigateTo }}
              reloadDocument
              style={{ width: '100%' }}
            >
              <Button label='Schedule new appointment' fullWidth />
            </Link>
            <LinkScl
              href={constants.TRAVEL_HOME_PAGE_URL}
              sx={{ width: '100%' }}
              data-testid='closePageButtonLink'
            >
              <Button
                label='Close page'
                fullWidth
                variant='outlined'
                href={constants.TRAVEL_HOME_PAGE_URL}
              />
            </LinkScl>
          </>
        ),
      }}
    />
  );
}

export default AppointmentCancelled;
