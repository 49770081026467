import {
  Box,
  Paper,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import './DestinationCard.css';
import type { Destination } from '@/features/destinations/utils/Destination';
import useThemeStore from '@/store/ThemeStore';

interface BaseDestinationCardProps {
  label: string;
  imageUrl: string;
  destination: Destination;
  variant: 'interactive' | 'static';
}

interface InteractiveDestinationCardProps extends BaseDestinationCardProps {
  variant: 'interactive';
  isSelected: boolean;
  onSelect: (destination: Destination) => void;
}

interface StaticDestinationCardProps extends BaseDestinationCardProps {
  variant: 'static';
}

type DestinationCardProps =
  | InteractiveDestinationCardProps
  | StaticDestinationCardProps;

const DestinationCard = ({
  label,
  destination,
  imageUrl,
  variant,
  ...props
}: DestinationCardProps) => {
  const isInteractive = variant === 'interactive';
  const { isSelected, onSelect } = isInteractive
    ? (props as InteractiveDestinationCardProps)
    : { isSelected: false, onSelect: () => {} };
  const onClickHandler = () => {
    onSelect(destination);
  };

  const { isLuxuryTheme } = useThemeStore();

  if (!isLuxuryTheme) {
    return (
      <Box
        onClick={onClickHandler}
        data-testid={`destinationCard-${destination.workTypeId}`}
        tabIndex={0}
        onKeyDown={(event) => {
          if (!isSelected) event.stopPropagation();
          if (event.key === 'Enter') {
            onClickHandler();
          }
        }}
        sx={{
          display: 'flex',
          borderRadius: '12px',
          transition: 'transform 0.2s',
          ':focus': {
            outline: (themes) => `4px solid ${themes.palette.primary[300]}`,
          },
          ...(isSelected && {
            outline: (themes) => `4px solid ${themes.palette.primary[300]}`,
          }),
        }}
      >
        <Paper
          elevation={2}
          sx={{
            height: 149,
            width: {
              xs: 148,
              sm: 148,
              md: 164,
              lg: 164,
            },
            overflow: 'hidden',
          }}
        >
          <Stack direction='column' justifyContent='center' alignItems='center'>
            <Box
              sx={{
                height: 100,
              }}
            >
              <img
                data-testid='destinationImages'
                src={imageUrl}
                alt={label}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box
              data-testid='destinationTitle'
              sx={{
                height: '49px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
              }}
            >
              <Typography
                variant='b1Medium'
                align='center'
                sx={{
                  lineHeight: '1rem !important',
                  padding: 1,
                  fontWeight: 500,
                  fontSize: '1rem !important',
                }}
              >
                {label}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>
    );
  }
  return (
    <Box
      sx={[
        {
          position: 'relative',
          height: {
            xs: 184,
            sm: 292,
          },
          width: {
            xs: 158,
            sm: 252,
          },
          display: 'flex',
          borderRadius: '12px',
          overflow: 'hidden',
        },
        isInteractive && {
          cursor: 'pointer',
          transition: 'transform 0.2s',
          ...(isSelected && {
            transform: 'translateY(-10px) scale(1.02)',
            boxShadow: '0 8px 15px rgba(0,0,0,0.3)',
            outline: (themes) => `4px solid ${themes.palette.primary[300]}`,
          }),
          ':hover': {
            transform: 'translateY(-10px) scale(1.02)',
            boxShadow: '0 8px 15px rgba(0,0,0,0.3)',
            '& img': {
              filter: 'brightness(0.9) !important',
            },
          },
          ':focus': {
            outline: (themes) => `4px solid ${themes.palette.primary[300]}`,
          },
        },
      ]}
      onClick={onClickHandler}
      data-testid={`destinationCard-${destination.workTypeId}`}
      tabIndex={0}
      onKeyDown={(event) => {
        if (!isSelected) event.stopPropagation();
        if (event.key === 'Enter') {
          onClickHandler();
        }
      }}
    >
      <img
        data-testid='destinationImagesLuxury'
        src={imageUrl}
        alt={label}
        width='100%'
        height='100%'
        style={{
          filter: isSelected ? 'brightness(0.9)' : 'brightness(0.7)',
          objectFit: 'cover',
          transition: 'opacity 0.3s',
        }}
      />
      <Typography
        variant='b1Medium'
        align='left'
        sx={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
            color: 'white',
            fontWeight: 500,
            fontSize: {
              xs: '27px !important',
              sm: '30px !important',
            },
            lineHeight: '42px !important',
            width: '100%',
            px: {
              xs: '11px',
              sm: '16px',
            },
            py: {
              xs: '14px',
              sm: '28px',
            },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        ]}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default DestinationCard;
