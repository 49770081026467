import DateTimePicker from '@/components/shared/DateTimePicker';
import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import ProgressBar from '@/components/shared/ProgressBar';
import SplitPageLayout from '@/components/shared/SplitPageLayout';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page, getClubCode } from '@/lib/tagging';
import useTagging from '@/lib/tagging/useTagging';
import { ErrorFallback } from '@/providers/app';
import useAppointmentStore from '@/store/AppointmentStore';
import useProgressStore from '@/store/ProgressStore';
import useSessionStore, { type FlowType } from '@/store/SessionStore';
import useThemeStore from '@/store/ThemeStore';
import {
  Alert,
  Backdrop,
  Box,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppointmentsQuery } from '../api/getAppointmentSlots';
import { useValidateSelectedTime } from '../api/useValidateSelectedTime';
import AgentsSelection from '../components/AgentsSelection';
import NoAgentsFound from '../components/NoAgentsFound';
import NoTimeSlotSelected from '../components/NoTimeslotSelected';
import type { AgentInfo } from '../utils/AgentInfo';
import type { TimeSlot } from '../utils/TimeSlot';
import { shuffleArray } from '../utils/shuffleArray';

const ScheduleAppointmentLuxury = () => {
  const [isReschedulingError, setIsReschedulingError] = useState(false);
  const [isProcessingAppointmentUpdate, setIsProcessingAppointmentUpdate] =
    useState(false);
  const [hasSetAgentForReschedule, setHasSetAgentForReschedule] =
    useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const {
    selectedDestination,
    destinations,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    shouldUseDefaultWorktype,
    setSelectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
    selectedAgent,
    setSelectedAgent,
    setShouldUseDefaultWorktype,
  } = useAppointmentStore();

  const { sessionId, sessionSource, setFlowType } = useSessionStore();

  const { isLuxuryTheme } = useThemeStore();

  const { from } = useProgressStore();

  const isFromContactInfo = from.includes('contact-info');
  const clubCode = useMemo(() => getClubCode(), []);

  const luxuryDestination = useMemo(
    () =>
      destinations.find(
        (destination) => destination.name?.toLowerCase() === 'luxury',
      ),
    [destinations],
  );

  const navigate = useNavigate();
  const location = useLocation();

  const redirectTo = useLandingPageRedirection();

  const {
    isPending,
    data,
    isLoading,
    isFetching,
    isError,
    refetch,
    isRefetching,
  } = useAppointmentsQuery(
    shouldUseDefaultWorktype
      ? (luxuryDestination?.workTypeId ?? '')
      : selectedDestination.workTypeId,
    clubCode,
    {
      enabled: !isFromContactInfo,
    },
    isLuxuryTheme,
  );

  const agentsAndTimeSlots = useMemo(() => {
    if (!data) return;
    if (!data.length) return [];
    const shuffledData = shuffleArray(data);
    return shuffledData;
  }, [data]);

  const isRescheduleAppointment = useMemo(
    () => location.pathname.includes('reschedule-appointment'),
    [location.pathname],
  );

  const tagging = useTagging();

  let flow: FlowType;
  if (isRescheduleAppointment) {
    flow = 'modify';
  } else {
    flow = '';
  }

  useEffect(() => {
    setFlowType(flow);
    if (agentsAndTimeSlots) {
      tagging.tag('view', sessionSource, {
        page: Page.selectAppointment,
        sessionid: sessionId,
        flow: flow,
        trv_end_location: selectedDestination.name,
      });
    }
  }, [
    agentsAndTimeSlots,
    selectedDestination.name,
    sessionSource,
    sessionId,
    setFlowType,
    tagging.tag,
    flow,
  ]);

  const timeSlots: TimeSlot[] = useMemo(() => {
    if (!agentsAndTimeSlots || !agentsAndTimeSlots.length) {
      return [];
    }
    const allTimeSlots = agentsAndTimeSlots.flatMap((agent) =>
      agent.timeSlots.map((timeSlot) => ({
        startTime: timeSlot.startTime,
        endTime: timeSlot.endTime,
      })),
    );
    const uniqueTimeSlots = Array.from(
      new Set(allTimeSlots.map((a) => a.startTime)),
    ).map(
      (startTime) =>
        allTimeSlots.find((a) => a.startTime === startTime) as TimeSlot,
    );
    const sortedTimeSlots = uniqueTimeSlots.sort(
      (a, b) => dayjs(a.startTime).unix() - dayjs(b.startTime).unix(),
    );
    return sortedTimeSlots;
  }, [agentsAndTimeSlots]);

  const matchingAgents = useMemo(() => {
    if (
      !agentsAndTimeSlots ||
      !selectedDate ||
      !selectedStartTime ||
      !selectedEndTime
    ) {
      return [];
    }
    return agentsAndTimeSlots.filter((agent) =>
      agent.timeSlots.some(
        (timeSlot) =>
          timeSlot.startTime === selectedStartTime &&
          timeSlot.endTime === selectedEndTime,
      ),
    );
  }, [agentsAndTimeSlots, selectedDate, selectedStartTime, selectedEndTime]);

  useEffect(() => {
    if (!selectedDate && timeSlots.length) {
      setSelectedDate(new Date(timeSlots[0]?.startTime));
    }
  }, [selectedDate, timeSlots, setSelectedDate]);

  useEffect(() => {
    if (
      isRescheduleAppointment &&
      agentsAndTimeSlots?.length &&
      selectedAgent.agentRecordId &&
      !hasSetAgentForReschedule
    ) {
      const currentAgent = agentsAndTimeSlots.find(
        (agent) => agent.agentRecordId === selectedAgent.agentRecordId,
      );
      if (!currentAgent) {
        setSelectedAgent(agentsAndTimeSlots[0]);
      } else {
        setSelectedDate(new Date(currentAgent.timeSlots[0].startTime));
        setSelectedStartTime(currentAgent.timeSlots[0].startTime);
        setSelectedEndTime(currentAgent.timeSlots[0].endTime);
      }
      setHasSetAgentForReschedule(true);
    }
  }, [
    isRescheduleAppointment,
    agentsAndTimeSlots,
    selectedAgent,
    setSelectedAgent,
    setSelectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
    hasSetAgentForReschedule,
  ]);

  useEffect(() => {
    if (isRescheduleAppointment && !hasSetAgentForReschedule) return;
    if (selectedStartTime) {
      if (matchingAgents.length) {
        const isCurrentAgentAvailable = matchingAgents.some(
          (agent) => agent.agentRecordId === selectedAgent.agentRecordId,
        );
        if (!isCurrentAgentAvailable) {
          setSelectedAgent(matchingAgents[0]);
        }
      } else {
        if (timeSlots.length) {
          handleDateChange(new Date(timeSlots[0]?.startTime));
        }
      }
    }
  }, [
    setSelectedAgent,
    matchingAgents,
    selectedAgent,
    selectedStartTime,
    timeSlots,
    isRescheduleAppointment,
    hasSetAgentForReschedule,
  ]);

  const handleDateChange = useCallback(
    (date: Date | undefined) => {
      setSelectedDate(date);
      setSelectedStartTime('');
      setSelectedEndTime('');
    },
    [setSelectedDate, setSelectedStartTime, setSelectedEndTime],
  );

  const handleTimeSlotChange = useCallback(
    (timeSlot: TimeSlot | null) => {
      setSelectedStartTime(timeSlot?.startTime || '');
      setSelectedEndTime(timeSlot?.endTime || '');
    },
    [setSelectedStartTime, setSelectedEndTime],
  );

  const handleProcessingAppointmentUpdate = useCallback(() => {
    setIsProcessingAppointmentUpdate(true);
  }, []);

  const handleInvalidTime = useCallback(
    (currentAgent?: AgentInfo) => {
      handleDateChange(
        new Date(
          currentAgent?.timeSlots[0]?.startTime ||
            matchingAgents[0].timeSlots[0].startTime,
        ),
      );
    },
    [handleDateChange, matchingAgents],
  );

  const handleAppointmentUpdateError = useCallback(() => {
    setIsReschedulingError(true);
    setIsProcessingAppointmentUpdate(false);
  }, []);

  const { isValidTime, isValidatingTimeSlot, validateSelectedTime } =
    useValidateSelectedTime({
      isRescheduleAppointment,
      refetch,
      onValidForReschedule: handleProcessingAppointmentUpdate,
      onInvalidTime: handleInvalidTime,
      onUpdateAppointmentError: handleAppointmentUpdateError,
    });

  const handleClickContinue = useCallback(() => {
    validateSelectedTime();
  }, [validateSelectedTime]);

  useEffect(() => {
    if (isLuxuryTheme && agentsAndTimeSlots && !agentsAndTimeSlots.length) {
      setShouldUseDefaultWorktype(true);
    }
  }, [isLuxuryTheme, agentsAndTimeSlots, setShouldUseDefaultWorktype]);

  useEffect(() => {
    if (agentsAndTimeSlots && !isValidTime) {
      setAlertOpen(true);
    }
  }, [agentsAndTimeSlots, isValidTime]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  if (isReschedulingError) {
    return <ErrorPage onClickTryAgain={() => navigate(redirectTo)} />;
  }

  if (
    (isPending ||
      isLoading ||
      isFetching ||
      (isLuxuryTheme &&
        !agentsAndTimeSlots?.length &&
        !shouldUseDefaultWorktype)) &&
    !isValidatingTimeSlot &&
    !isError
  ) {
    return (
      <LoadingContainer
        message='Searching for specialized advisors'
        radiosInfo={[
          {
            radioMessage: 'Retrieving available time slots',
            isChecked: true,
          },
          {
            radioMessage: 'Retrieving advisor information',
            isChecked: false,
          },
        ]}
      />
    );
  }

  if (isRefetching || isValidatingTimeSlot || isProcessingAppointmentUpdate) {
    const radiosInfo = [
      {
        radioMessage: 'Verifying advisor availability',
        isChecked: true,
      },
    ];

    if (isRescheduleAppointment) {
      return (
        <LoadingContainer
          message='Confirming your new appointment date and time...'
          radiosInfo={radiosInfo}
        />
      );
    }
    return (
      <LoadingContainer
        message='Confirming your appointment date and time...'
        radiosInfo={radiosInfo}
      />
    );
  }

  if (isError) {
    return <ErrorPage onClickTryAgain={refetch} />;
  }

  if ((agentsAndTimeSlots?.length === 0 || !agentsAndTimeSlots) && !isError) {
    return (
      <NoAgentsFound
        onClickTryAgain={() => {
          navigate('/', { replace: true });
        }}
      />
    );
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Backdrop
        open={alertOpen}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClick={handleCloseAlert}
      >
        <Alert
          visible={true}
          severity={'error'}
          sx={{
            position: 'absolute',
            top: '5%',
            left: '50%',
            transform: 'translate(-50%, -0%)',
            width: '80%',
            '& .MuiAlert-Typography-root': {
              color: 'secondary.300',
            },
            '& .MuiIconButton-root.MuiAlertCloseIconButton': {
              color: 'secondary.300',
            },
          }}
          data-testid='invalidTimeSlotAlert'
          onClose={handleCloseAlert}
        >
          That date & time has already been booked. Please select a different
          time.
        </Alert>
      </Backdrop>
      <Box
        data-testid='scheduleAppointmentContainer'
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <SplitPageLayout
          leftSide={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              {!selectedStartTime ? (
                <NoTimeSlotSelected />
              ) : (
                <AgentsSelection
                  agents={matchingAgents}
                  selectedAgent={selectedAgent}
                  onSelectAgent={setSelectedAgent}
                />
              )}
            </Box>
          }
          rightSide={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'space-between',
                width: '100%',
                mt: {
                  xs: 2,
                  lg: isLuxuryTheme ? 12 : 1,
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  px: {
                    xs: 1,
                    sm: 0,
                  },
                }}
                data-testid={'datePickerContainer'}
              >
                <Stack sx={{ marginBottom: '16px' }}>
                  <Typography variant='h4'>
                    Select date & time to speak with an advisor
                  </Typography>
                  <Typography
                    variant='h6'
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Book an appointment
                  </Typography>
                </Stack>
                <DateTimePicker
                  timeSlots={timeSlots}
                  selectedDate={selectedDate || new Date()}
                  selectedTimeSlot={{
                    startTime: selectedStartTime || '',
                    endTime: selectedEndTime || '',
                  }}
                  onChangeDate={handleDateChange}
                  onChangeTimeSlot={handleTimeSlotChange}
                />
              </Box>
              <ProgressBar
                buttonDisabled={
                  !selectedAgent?.agentRecordId || !selectedStartTime
                }
                displayBackButton={true}
                onClickContinue={handleClickContinue}
              />
            </Box>
          }
        />
      </Box>
    </ErrorBoundary>
  );
};

export default ScheduleAppointmentLuxury;
