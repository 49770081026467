import {
  AppHeader,
  Box,
  Container,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { ulid } from 'ulid';

import ErrorPage from '@/components/shared/ErrorPage';
import LoadingContainer from '@/components/shared/LoadingContainer';
import ProgressBar from '@/components/shared/ProgressBar';
import { constants } from '@/constants';
import { Page } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';

import { destinationRegionLabelMapper } from '@/features/destinations/utils/DestinationRegionLabelMapper';
import useTagging from '@/lib/tagging/useTagging';
import useProgressStore from '@/store/ProgressStore';
import useTSTStore from '@/store/TSTStore';
import useThemeStore from '@/store/ThemeStore';
import { FlowType } from '@/types/FlowType';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDestinations } from '../api/getDestinations';
import DestinationCard from '../components/DestinationCard';
import type { Destination } from '../utils/Destination';
import { getDestinationImageUrl } from '../utils/DestinationRegionImageMapper';
import type { ApiRegion, Region } from '../utils/Region';

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
};
interface DestinationsProps {
  flow: FlowType;
  isLuxuryFlow?: boolean;
}
function Destinations({ flow, isLuxuryFlow = false }: DestinationsProps) {
  const { isLuxuryTheme } = useThemeStore();

  const { isPending, isError, isSuccess, data, refetch, error } =
    useDestinations(isLuxuryFlow);

  const {
    setDestinations,
    selectedDestination,
    selectedAgent,
    setSelectedDestination,
    setSelectedDate,
    setSelectedStartTime,
    setSelectedEndTime,
    setSelectedAgent,
    setShouldUseDefaultWorktype,
  } = useAppointmentStore();
  const {
    sessionId,
    setSessionId,
    setSessionSource,
    sessionSource,
    flowType,
    setFlowType,
  } = useSessionStore();

  const { setGeneratedRandomAgent, setRandomGeneratedAgentIndex } =
    useProgressStore();

  const location = useLocation();

  const { setTSTParams } = useTSTStore();

  const tagging = useTagging();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // https://travel.calif.aaa.com/cruise cruise has the redirection url query param as tstUrl
    // https://travel.calif.aaa.com/tour tour has the redirection url query param as tstURL
    const tstTripUrl = searchParams.get('tstUrl') || searchParams.get('tstURL');

    setTSTParams({
      TSTTripURL: tstTripUrl || '',
    });
  }, [setTSTParams, location.search]);

  const resetTimesIfDestinationChanged = (destinationWorkTypeId: string) => {
    if (selectedDestination?.workTypeId !== destinationWorkTypeId) {
      setSelectedStartTime('');
      setSelectedEndTime('');
      setSelectedDate(undefined);
    }
  };

  const resetAgentRandomizationIfDestinationChanged = (
    destinationWorkTypeId: string,
  ) => {
    if (selectedDestination?.workTypeId !== destinationWorkTypeId) {
      if (selectedAgent?.resourceId) setSelectedAgent({});
      setGeneratedRandomAgent(false);
      setRandomGeneratedAgentIndex(0);
    }
  };

  useEffect(() => {
    if (data?.length) {
      setDestinations(data);
    }
  }, [data, setDestinations]);

  const handleDestinationSelection = (destination: Destination) => {
    resetAgentRandomizationIfDestinationChanged(destination.workTypeId);
    resetTimesIfDestinationChanged(destination.workTypeId);
    setSelectedDestination(destination);
    setShouldUseDefaultWorktype(false);
  };

  const filteredDestinations: Destination[] | undefined = data
    ?.map((destination: Destination) => {
      const strippedName = destination.name?.replace(/luxury/i, '').trim();
      return {
        ...destination,
        name: strippedName,
      };
    })
    .filter((destination: Destination) => destination.name !== '')
    .map((destination: Destination) => ({
      ...destination,
      name: destinationRegionLabelMapper[destination.name as ApiRegion],
    }))
    .sort((a, b) => {
      const indexA = constants.DESTINATION_ORDER.indexOf(a.name);
      const indexB = constants.DESTINATION_ORDER.indexOf(b.name);
      return indexA - indexB;
    });

  useEffect(() => {
    if (flow === FlowType.EMAIL) {
      setFlowType('emailInquiry');
    }
  }, [flow, setFlowType]);

  useEffect(() => {
    if (isSuccess && !isPending && !isError) {
      const newSessionId = ulid();
      if (!sessionId) {
        setSessionId(newSessionId);
      }
      setSessionSource('new');
      if (sessionSource) {
        tagging.tag('view', sessionSource || 'new', {
          page: Page.destination,
          sessionid: newSessionId,
          flow: flowType || '',
        });
      }
    }
  }, [
    isSuccess,
    isPending,
    isError,
    setSessionId,
    sessionId,
    setSessionSource,
    flowType,
    sessionSource,
    tagging.tag,
  ]);

  const navigate = useNavigate();

  const handleOnClickContinue = () => {
    if (selectedDestination?.workTypeId) {
      if (flow === FlowType.EMAIL) {
        navigate(
          `/email-inquiry/${selectedDestination?.workTypeId}/contact-info`,
        );
      } else {
        navigate(`schedule-appointment/${selectedDestination?.workTypeId}`);
      }
    }
  };

  const getHeaderText = () => {
    return constants.DESTINATION_HEADER_TITLE_TEXT_DEFAULT;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: handleOnClickContinue won't change
  useEffect(() => {
    const handleEnterKey = (e: KeyboardEvent) => {
      if (selectedDestination && e.key === 'Enter') {
        handleOnClickContinue();
      }
    };

    window.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('keydown', handleEnterKey);
    };
  }, [selectedDestination]);

  if (isPending || error?.message === constants.TANSTACK_ABORTED_ERROR) {
    const loadingMessage =
      flow === FlowType.EMAIL
        ? 'Your advisor app is loading...'
        : 'Your new adventure awaits...';

    return <LoadingContainer message={loadingMessage} showRadios={false} />;
  }

  if (isError && error.message !== constants.TANSTACK_ABORTED_ERROR) {
    return (
      <ErrorPage
        onClickTryAgain={() => {
          refetch();
        }}
      />
    );
  }

  if (filteredDestinations?.length) {
    return (
      <>
        <motion.article
          initial='hidden'
          animate='visible'
          exit={{ opacity: 0, transition: { duration: 1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        >
          <Box
            sx={[
              {
                backgroundColor: 'background.default',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
              isLuxuryTheme && {
                backgroundImage:
                  'url(/travel/agent/scheduler/luxuryMapBackgroundImage.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              },
            ]}
          >
            {!isLuxuryTheme && (
              <Stack
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                sx={{
                  position: 'relative',
                  width: '72px',
                  height: '44px',
                  maxHeight: '44px',
                }}
              >
                <motion.h1
                  variants={{
                    hidden: { opacity: 0, y: -20 },
                    visible,
                  }}
                  style={
                    {
                      '--base-width': '24vw',
                      letterSpacing: '-1.4vw',
                      margin: 0,
                      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    } as any
                  }
                >
                  <AppHeader sx={{ marginTop: 0 }} />
                </motion.h1>
              </Stack>
            )}
            <Container
              maxWidth={isLuxuryTheme ? 'lg' : 'md'}
              data-testid='destinationsContainer'
              sx={{
                px: 1,
              }}
            >
              <Stack
                direction={'column'}
                sx={{
                  alignItems: 'center',
                  width: '100%',
                  paddingBottom: '100px',
                  paddingTop: {
                    lg: '100px',
                    xs: '53px',
                  },
                }}
              >
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: -20 },
                    visible,
                  }}
                  style={
                    {
                      top: '-18vw',
                      marginTop: 0,
                      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    } as any
                  }
                >
                  <Typography
                    variant='h4'
                    fontWeight={600}
                    sx={{
                      fontSize: {
                        xs: '1.5rem',
                        sm: '1.875rem',
                        ...(isLuxuryTheme && { sm: '2.313rem' }),
                      },
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '1047px',
                      },
                    }}
                  >
                    {getHeaderText()}
                  </Typography>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    useFlexGap
                    sx={{
                      justifyContent: 'center',
                      mt: {
                        xs: '2.5rem',
                        md: '3.375rem',
                      },
                      gap: {
                        xs: 2,
                        sm: 4,
                      },
                      rowGap: {
                        xs: 3,
                        sm: 4,
                      },
                    }}
                    data-testid='destinationsGrid'
                  >
                    {filteredDestinations?.map((destination: Destination) => {
                      return (
                        <DestinationCard
                          key={destination.workTypeId}
                          destination={destination}
                          imageUrl={getDestinationImageUrl(
                            isLuxuryTheme,
                            destination.name as Region,
                          )}
                          label={destination.name}
                          variant='interactive'
                          onSelect={handleDestinationSelection}
                          isSelected={
                            selectedDestination?.workTypeId ===
                            destination.workTypeId
                          }
                        />
                      );
                    })}
                  </Stack>
                </motion.div>
              </Stack>
            </Container>
          </Box>
        </motion.article>
        <ProgressBar
          onClickContinue={handleOnClickContinue}
          buttonDisabled={!selectedDestination?.workTypeId.length}
          fixed
          nextProgressValue={50}
          displayBackButton={false}
        />
      </>
    );
  }
}

export default Destinations;
