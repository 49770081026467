import { constants } from '@/constants';
import { Region } from '@/features/destinations/utils/Region';

export const DestinationRegionImageMapper: {
  [key in Region | string]?: string;
} = {
  [Region.Alaska]: 'alaska.png',
  [Region.EasternEurope]: 'eastern_europe.png',
  [Region.Africa]: 'africa.png',
  [Region.AustraliaAndNewZealand]: 'australia_new_zealand.png',
  [Region.Caribbean]: 'caribbean.png',
  [Region.CentralAndSouthAmerica]: 'central_south_america.png',
  [Region.CentralWesternEurope]: 'central_western_europe.png',
  [Region.Hawaii]: 'hawaii.png',
  [Region.ScandinaviaAndIceland]: 'scandinavia_iceland.png',
  [Region.SouthernEuropeAndMediterranean]: 'southern_europe_mediterranean.png',
  [Region.Asia]: 'asia.png',
  [Region.Mexico]: 'mexico.png',
  [Region.TahitiAndSouthPacific]: 'tahiti_south_pacific.png',
  [Region.UKAndIreland]: 'united_kingdom_ireland.png',
  [Region.USAAndCanada]: 'usa_canada.png',
  [Region.Other]: 'other.png',
};

export const getDestinationImageUrl = (
  isLuxury: boolean,
  region: Region,
): string => {
  const baseUrl = isLuxury
    ? constants.DESTINATION_IMAGES_PATH_LUXURY
    : constants.DESTINATION_IMAGES_PATH;

  return `${constants.APP_BASE_URL}${baseUrl}/${DestinationRegionImageMapper[region]}`;
};
