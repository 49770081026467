import useThemeStore from '@/store/ThemeStore';
import {
  Box,
  CircularProgress,
  ModalActionButton,
  ModalActions,
  Typography,
} from '@clublabs/shared-component-library';

interface CancelAppointmentModalBodyProps {
  onClickCancel: () => void;
  onClickClose: () => void;
  isCancellingAppointment: boolean;
}

function CancelAppointmentModalBody({
  onClickCancel,
  onClickClose,
  isCancellingAppointment,
}: CancelAppointmentModalBodyProps) {
  const { isLuxuryTheme } = useThemeStore();
  return (
    <Box
      sx={{
        padding: '0 25px 25px 25px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        data-testid='warningMessage'
        sx={[
          isLuxuryTheme && {
            color: 'secondary.400',
          },
        ]}
      >
        If you cancel your appointment, the same date and time may not be
        available if you decide to reschedule.
      </Typography>
      <ModalActions
        actionLayout={'shared'}
        sx={{
          justifyContent: 'center',
        }}
      >
        <ModalActionButton
          onClick={onClickCancel}
          variant='outlined'
          label='Yes, cancel'
          endIcon={
            isCancellingAppointment ? (
              <CircularProgress size={20} data-testid='circularProgress' />
            ) : null
          }
        />
        <ModalActionButton
          label='Keep appointment'
          onClick={onClickClose}
          sx={{
            lineHeight: '1rem',
          }}
        />
      </ModalActions>
    </Box>
  );
}

export default CancelAppointmentModalBody;
