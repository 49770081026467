import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect, useMemo } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';

import ErrorPage from '@/components/shared/ErrorPage';
import { queryClient } from '@/lib/queryClient';
import { initLogCannon } from '@/providers/log-cannon';
import { defaultRouter, luxuryRouter } from '@/routes';
import useThemeStore from '@/store/ThemeStore';
import { lightTheme, luxuryTheme } from '@/theming';
import {
  CircularProgress,
  ThemeProvider,
} from '@clublabs/shared-component-library';

export const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return <ErrorPage onClickTryAgain={resetErrorBoundary} />;
};

export const AppProvider = () => {
  initLogCannon();
  const { setTheme } = useThemeStore();

  const path = location.pathname;
  const isLuxuryPath = useMemo(() => path.includes('luxury'), [path]);

  useEffect(() => {
    setTheme(isLuxuryPath ? 'luxury' : 'regular');
  }, [setTheme, isLuxuryPath]);

  return (
    <React.Suspense
      fallback={
        <div className='flex items-center justify-center w-screen h-screen'>
          <CircularProgress />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
          <ThemeProvider theme={isLuxuryPath ? luxuryTheme : lightTheme}>
            <RouterProvider
              router={isLuxuryPath ? luxuryRouter : defaultRouter}
            />
          </ThemeProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
