import { constants } from '@/constants';
import type { FlowType, SessionSource } from '@/store/SessionStore';
import cookieManager from '../cookieHelper';

export const getClubCode = () => {
  const zipCookie = cookieManager('zipcode');
  if (zipCookie !== null) {
    const clubcode = zipCookie.split('|')[2];
    return clubcode ? clubcode.padStart(3, '0') : '';
  }
  return '';
};

export const deriveFlowTag = (
  sessionSource: SessionSource,
  flowType: FlowType,
): string => {
  if (sessionSource === 'new' && flowType === '') return 'new';

  if (sessionSource === 'new' && flowType === 'modify') return 'new:modify';

  if (sessionSource === 'new' && flowType === 'cancel') return 'new:cancel';

  if (sessionSource === 'email' && flowType === '') return 'email';

  if (sessionSource === 'new' && flowType === 'emailInquiry')
    return 'advisor inquiry';

  if (sessionSource === 'email' && flowType === 'modify') return 'email:modify';

  if (sessionSource === 'email' && flowType === 'cancel') return 'email:cancel';

  return 'new';
};

export const loadUtagJs = () => {
  if (window.utagLoadAttempt) return;
  window.utagLoadAttempt = true;
  const utagJsScript = document.createElement('script');
  utagJsScript.src = constants.UTAG_SCRIPT_SRC;
  utagJsScript.type = 'text/javascript';
  utagJsScript.async = true;
  document.body.appendChild(utagJsScript);
};
