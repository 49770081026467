import type { FlowType } from '@/store/SessionStore';

export interface UTag {
  link: (params: Record<string, unknown>) => void;
  view: (params: Record<string, unknown>) => void;
}

export type ActivityType = 'view' | 'link';

export interface TagParams {
  sessionid: string;
  page: string;
  flow: FlowType;
  trv_end_location?: string;
  cta?: string;
  confirmation_id?: string;
  category?: Array<string>;
  comm_pref?: string;
  custID?: string;
  quantity?: Array<string>;
  product?: Array<string>;
  error?: string;
}

export interface Flows {
  new: string;
  modify: string;
  cancel: string;
  email: string;
  emailModify: string;
  emailCancel: string;
}

export interface Pages {
  destination: string;
  selectAppointment: string;
  selectAgent: string;
  anotherAgent: string;
  contactInfo: string;
  confirmation: string;
  cancelAppointment: string;
  errorPage: string;
}

export interface CTAS {
  pickAnotherAgent: string;
}

export const Page: Pages = {
  destination: 'travel:scheduler:destination',
  selectAppointment: 'travel:scheduler:select appointment',
  selectAgent: 'travel:scheduler:select an agent',
  anotherAgent: 'travel:scheduler:select appointment-pick another agent',
  contactInfo: 'travel:scheduler:contact information',
  confirmation: 'travel:scheduler:confirmation',
  cancelAppointment: 'travel:scheduler:cancel appointment',
  errorPage: 'travel:scheduler:error',
};

export const CTA: CTAS = {
  pickAnotherAgent: 'travel:scheduler:select appointment-pick another agent',
};

export const Flow: Flows = {
  new: 'new',
  modify: 'new:modify',
  cancel: 'new:cancel',
  email: 'email',
  emailModify: 'email:modify',
  emailCancel: 'email:cancel',
};
