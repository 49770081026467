import { apiWrapper } from '@/apiWrapper';
import { getEndpoints } from '@/endpoints';
import { useQuery } from '@tanstack/react-query';

export const getAppointmentInformation = async (
  appointmentId: string,
): Promise<GetAppointmentInfoResponseData> => {
  const url = getEndpoints().getAppointmentInformation.replace(
    '{appointmentId}',
    appointmentId,
  );
  const config = { method: 'GET' };

  return await apiWrapper<GetAppointmentInfoResponseData>(url, config);
};

export const useAppointmentInfoQuery = (appointmentId: string) => {
  return useQuery({
    queryKey: ['appointmentInformation'],
    queryFn: () => getAppointmentInformation(appointmentId),
  });
};

export interface GetAppointmentInfoResponseData {
  appointmentId: string;
  confirmationId: string;
  name: string;
  agentUserId: string;
  workTypeId: string;
  schedStartTime: string;
  schedEndTime: string;
  image: string;
  destination: string;
  serviceTerritoryId: string;
  appointmentServiceResourceId: string;
  email: string;
  customerId: string;
  status?: string;
}
