import useThemeStore from '@/store/ThemeStore';
import { AppHeader, Box, Stack } from '@clublabs/shared-component-library';
import type React from 'react';
import type { ReactNode } from 'react';

type SplitPageLayoutProps = {
  leftSide: ReactNode;
  rightSide: ReactNode;
};

const SplitPageLayout: React.FC<SplitPageLayoutProps> = ({
  leftSide,
  rightSide,
}) => {
  const { isLuxuryTheme } = useThemeStore();
  return (
    <Stack
      sx={[
        {
          display: 'flex',
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
          minHeight: '100%',
          width: '100%',
          backgroundColor: 'grey.100',
        },
        isLuxuryTheme && {
          backgroundColor: 'background.default',
        },
      ]}
    >
      <Stack
        direction='column'
        sx={[
          {
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: {
              xs: '100%',
              lg: '35%',
            },
            backgroundColor: 'grey.100',
            height: {
              xs: 'auto',
              lg: '100vh',
            },
            overflow: {
              xs: 'visible',
              lg: 'auto',
            },
          },
          isLuxuryTheme && {
            backgroundColor: '#16262c',
            backgroundImage:
              'url(/travel/agent/scheduler/luxuryLeftSideBackground.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        ]}
      >
        <AppHeader
          sx={{
            display: isLuxuryTheme
              ? 'none'
              : {
                  lg: 'none',
                  xs: 'block',
                },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {leftSide}
        </Box>
      </Stack>
      <Stack
        direction='column'
        sx={[
          {
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: {
              xs: '100%',
              lg: '65%',
            },
            backgroundColor: 'background.default',
            height: {
              xs: 'auto',
              lg: '100vh',
            },
            overflow: {
              xs: 'visible',
              lg: 'auto',
            },
          },
          isLuxuryTheme && {
            backgroundImage:
              'url(/travel/agent/scheduler/luxuryMapBackgroundImage.png)',
            backgroundSize: '170%',
            backgroundPosition: '90% 85%',
          },
        ]}
      >
        <AppHeader
          sx={{
            display: isLuxuryTheme
              ? 'none'
              : {
                  lg: 'block',
                  xs: 'none',
                },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {rightSide}
        </Box>
      </Stack>
    </Stack>
  );
};

export default SplitPageLayout;
