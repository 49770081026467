import { constants } from '@/constants';
import useLandingPageRedirection from '@/hooks/useLandingPageRedirection';
import { Page } from '@/lib/tagging';
import useTagging from '@/lib/tagging/useTagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import useThemeStore from '@/store/ThemeStore';
import {
  AppHeader,
  Link as BaseLink,
  Box,
  Button,
  Card,
  Container,
  Icon,
  type LinkProps,
  Stack,
  Typography,
} from '@clublabs/shared-component-library';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

interface EnhancedLinkProps extends LinkProps {
  testId?: string;
}

const Link: React.FC<EnhancedLinkProps> = ({
  children,
  sx,
  testId,
  ...props
}) => (
  <BaseLink
    {...props}
    variant='body1'
    sx={{
      ...sx,
    }}
    data-testid={testId}
  >
    {children}
  </BaseLink>
);

const EmailConfirmation = () => {
  useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );
  useLandingPageRedirection();

  const { sessionId, flowType, sessionSource } = useSessionStore();
  const { selectedDestination, confirmationId } = useAppointmentStore();
  const { isLuxuryTheme } = useThemeStore();
  const tagging = useTagging();

  useEffect(() => {
    tagging.tag('view', sessionSource, {
      page: Page.confirmation,
      sessionid: sessionId,
      flow: flowType,
      trv_end_location: selectedDestination.name,
      category: ['travel:scheduler'],
      confirmation_id: confirmationId,
      product: ['inquiry'],
      quantity: ['1'],
    });
  }, [
    sessionId,
    flowType,
    sessionSource,
    selectedDestination.name,
    confirmationId,
    tagging.tag,
  ]);

  const getSubmissionHeading = () => {
    return 'Your request has been sent.';
  };

  return (
    <Box
      sx={[
        {
          backgroundColor: '#F7F8FA',
          minHeight: '100vh',
        },
        isLuxuryTheme && {
          backgroundImage:
            'url(/travel/agent/scheduler/luxuryLeftSideBackground.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      ]}
    >
      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack
          direction='column'
          alignItems='center'
          spacing={2}
          sx={[
            {
              pb: 2,
              maxWidth: '40.938rem',
            },
            isLuxuryTheme && {
              pt: 15,
            },
          ]}
        >
          {!isLuxuryTheme && <AppHeader data-testid='appHeader' />}
          <Typography variant='h4' data-testid='emailConfirmationTitle'>
            Thank you
          </Typography>
          <Card
            sx={{
              width: '100%',
              padding: '1.5rem',
              pb: 4,
            }}
            data-testid='confirmationCard'
          >
            <Stack direction='column' spacing={2}>
              <Stack direction='row' alignItems='center' spacing={2}>
                <Icon
                  iconName='envelope-dot-duotone'
                  color='primary'
                  sx={{
                    width: '3.438rem',
                    height: '3rem',
                  }}
                  data-testid='icon-envelope-duotone'
                />
                <Typography
                  variant='h5'
                  align='left'
                  data-testid='requestSubmittedMessage'
                  sx={[
                    isLuxuryTheme && {
                      color: 'secondary.main',
                    },
                    {
                      ml: '5px !important',
                    },
                  ]}
                >
                  {getSubmissionHeading()}
                </Typography>
              </Stack>
              <EmailConfirmationRegularContent isLuxuryTheme={isLuxuryTheme} />
            </Stack>
          </Card>
          <Link
            sx={{
              textDecoration: 'none',
              width: '100%',
            }}
            href={constants.TRAVEL_HOME_PAGE_URL}
            testId='closePageLink'
          >
            <Button
              variant='contained'
              sx={{
                marginTop: '1rem',
                width: '100%',
              }}
              label='Close Page'
              data-testId='closePageButton'
            />
          </Link>
        </Stack>
      </Container>
    </Box>
  );
};

const EmailConfirmationRegularContent = ({
  isLuxuryTheme,
}: {
  isLuxuryTheme: boolean;
}) => (
  <Typography
    data-testid='confirmationMessage'
    variant='h6'
    sx={[
      {
        textAlign: 'left',
        fontWeight: 'normal',
        marginTop: '26px !important',
        marginBottom: '21px !important',
      },
      isLuxuryTheme && {
        color: 'secondary.main',
      },
    ]}
  >
    An advisor will contact you in the next 48 hours, or on the next business
    day if you sent your request over the weekend.
  </Typography>
);

export default EmailConfirmation;
