import ErrorPage from '@/components/shared/ErrorPage';
import AppointmentCancelled from '@/features/confirmation/components/AppointmentCancelled';
import Confirmation from '@/features/confirmation/routes/Confirmation';
import EmailConfirmation from '@/features/confirmation/routes/EmailConfirmation';
import Destinations from '@/features/destinations/routes/Destinations';
import LoadAppointment from '@/features/loadAppointment/routes/LoadAppointment';

import { constants } from '@/constants';
import ContactInfo from '@/features/appointments/routes/ContactInfo';
import EmailInquiry from '@/features/appointments/routes/EmailInquiry';
import ScheduleAppointment from '@/features/appointments/routes/ScheduleAppointment';
import { FlowType } from '@/types/FlowType';
import { createBrowserRouter } from 'react-router-dom';

const baseName = constants.APP_BASE_URL;

const location = window.location.pathname;
const emailFlowPaths = ['/email', '/email-inquiry', '/email-confirmation'];

const isEmailFlow = emailFlowPaths.some((path) => location.startsWith(path));

const redirectPath = isEmailFlow ? `${constants.APP_BASE_URL}/email` : baseName;

export const createRoutesConfiguration = (isLuxury = false) => {
  return [
    {
      path: '/',
      element: (
        <Destinations flow={FlowType.SCHEDULE} isLuxuryFlow={isLuxury} />
      ),
    },
    {
      path: '/email',
      element: <Destinations flow={FlowType.EMAIL} isLuxuryFlow={isLuxury} />,
    },
    {
      path: '/schedule-appointment/:id',
      element: <ScheduleAppointment />,
    },
    {
      path: '/schedule-appointment/:id/contact-info',
      element: <ContactInfo />,
    },
    { path: '/confirmation', element: <Confirmation /> },
    { path: '/load-appointment', element: <LoadAppointment /> },
    {
      path: '/email-inquiry/:id/contact-info',
      element: <EmailInquiry />,
    },
    {
      path: '/email-confirmation',
      element: <EmailConfirmation />,
    },
    {
      path: '/reschedule-appointment/:id',
      element: <ScheduleAppointment />,
    },
    {
      path: '/cancel-appointment/:id',
      element: <AppointmentCancelled />,
    },
    {
      path: '/*',
      element: (
        <ErrorPage
          onClickTryAgain={() => {
            window.location.replace(redirectPath);
          }}
        />
      ),
    },
  ];
};

export const defaultRoutesConfiguration = createRoutesConfiguration();
export const luxuryRoutesConfiguration = createRoutesConfiguration(true);

export const defaultRouter = createBrowserRouter(defaultRoutesConfiguration, {
  basename: constants.APP_BASE_URL,
});

export const luxuryRouter = createBrowserRouter(luxuryRoutesConfiguration, {
  basename: `${constants.APP_BASE_URL}/luxury`,
});
