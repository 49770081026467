import { create } from 'zustand';

interface ProgressState {
  showProgress: boolean;
  progress: number;
  generatedRandomAgent: boolean;
  randomGeneratedAgentIndex: number;
  from: string;
  setShowProgress: (show: boolean) => void;
  setProgress: (progress: number) => void;
  setGeneratedRandomAgent: (generatedRandomAgent: boolean) => void;
  setRandomGeneratedAgentIndex: (index: number) => void;
  setFrom: (from: string) => void;
}

const useProgressStore = create<ProgressState>((set) => ({
  showProgress: true,
  progress: 0,
  generatedRandomAgent: false,
  randomGeneratedAgentIndex: 0,
  from: '',
  setShowProgress: (show) => set({ showProgress: show }),
  setProgress: (progress) => set({ progress: progress }),
  setGeneratedRandomAgent: (generatedRandomAgent) =>
    set({ generatedRandomAgent: generatedRandomAgent }),
  setRandomGeneratedAgentIndex: (index) =>
    set({ randomGeneratedAgentIndex: index }),
  setFrom: (from) => set({ from: from }),
}));

export default useProgressStore;
