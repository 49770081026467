import { constants } from '@/constants';
import { Page } from '@/lib/tagging';
import useTagging from '@/lib/tagging/useTagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useSessionStore from '@/store/SessionStore';
import useThemeStore from '@/store/ThemeStore';
import {
  AppHeader,
  Box,
  Button,
  Typography,
} from '@clublabs/shared-component-library';
import { useEffect } from 'react';
import { ulid } from 'ulid';

const ERROR_IMAGES = {
  default: `${constants.APP_BASE_URL}/errorImage.png`,
  luxury: `${constants.APP_BASE_URL}/luxuryErrorImage.png`,
};

interface ErrorPageProps {
  onClickTryAgain: () => void;
  message?: string;
  imageUrl?: string;
  buttonLabel?: string;
}

function ErrorPage({
  onClickTryAgain,
  message = 'Sorry, something went wrong on our end.',
  imageUrl,
  buttonLabel = 'Try again',
}: ErrorPageProps) {
  const { sessionId, sessionSource, flowType, setSessionId } =
    useSessionStore();
  const { selectedDestination } = useAppointmentStore();
  const { isLuxuryTheme } = useThemeStore();
  const tagging = useTagging();

  const defaultImageUrl = isLuxuryTheme
    ? ERROR_IMAGES.luxury
    : ERROR_IMAGES.default;

  useEffect(() => {
    if (!sessionId) {
      const newSessionId = ulid();
      setSessionId(newSessionId);
    } else {
      tagging.tag('view', sessionSource, {
        page: Page.errorPage,
        flow: flowType,
        trv_end_location: selectedDestination.name,
        sessionid: sessionId,
        error: 'system error',
      });
    }
  }, [
    sessionId,
    selectedDestination,
    sessionSource,
    flowType,
    setSessionId,
    tagging.tag,
  ]);

  return (
    <Box
      data-testid='errorPageContainer'
      sx={{
        backgroundColor: 'background.default',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {!isLuxuryTheme && (
        <AppHeader
          sx={{ position: 'absolute', top: 0 }}
          data-testid='appHeader'
        />
      )}
      <Typography
        variant='h1'
        sx={{
          fontSize: { xs: '100px', md: '150px', lg: '290px' },
          opacity: '23%',
          fontWeight: 700,
          color: '#D0D0D0',
          height: '290px',
        }}
        data-testid={'errorTitle'}
      >
        Oops!
      </Typography>
      <Box
        sx={{
          '& img': {
            backgroundSize: 'cover',
            position: 'absolute',
            top: '52%',
            left: '52%',
            transform: 'translate(-50%, -50%)',
            width: {
              lg: '354px',
              xs: '300px',
            },
            height: {
              lg: '135px',
              xs: '120px',
            },
            zIndex: 2,
          },
        }}
      >
        <img
          src={imageUrl || defaultImageUrl}
          aria-label='errorImage'
          alt='errorImage'
        />
      </Box>
      <Typography
        data-testid='errorMessage'
        variant='b1Regular'
        sx={{ marginBottom: '20px', marginTop: '20px' }}
      >
        {message}
      </Typography>
      <Button
        data-testid='tryAgainButton'
        label={buttonLabel}
        onClick={onClickTryAgain}
      />
    </Box>
  );
}

export default ErrorPage;
