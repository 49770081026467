import TimeSlot from '@/features/appointments/components/TimeSlot';
import type { TimeSlot as TimeSlotInterface } from '@/features/appointments/utils/TimeSlot';
import useScreenSize from '@/hooks/useScreenSize';
import useThemeStore from '@/store/ThemeStore';
import { Box, Stack, Typography } from '@clublabs/shared-component-library';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type DateTimePickerProps = {
  timeSlots: TimeSlotInterface[];
  selectedDate: Date;
  selectedTimeSlot: TimeSlotInterface | null;
  onChangeDate: (date: Date) => void;
  onChangeTimeSlot: (timeSlot: TimeSlotInterface | null) => void;
};

const getGenericTimeZoneName = () => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'longGeneric',
  });

  const formatted = formatter.format();
  const zoneName = formatted.split(',')[1].trim();
  return zoneName;
};

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  timeSlots,
  selectedDate,
  selectedTimeSlot,
  onChangeDate,
  onChangeTimeSlot,
}) => {
  const { isLuxuryTheme } = useThemeStore();

  const filteredDates = new Set(
    timeSlots.map((timeSlot) => dayjs(timeSlot.startTime).format('MM/DD/YYYY')),
  );

  const filteredSlots = timeSlots.filter(
    (timeSlot) =>
      dayjs(timeSlot.startTime).format('MM/DD/YYYY') ===
      dayjs(selectedDate).format('MM/DD/YYYY'),
  );

  const { width } = useScreenSize();

  const availableWidth = width - 16; // 16 px for padding
  const calendarScale = Math.min(1, availableWidth / 390); // 390 is the original width of the calendar
  const verticalDisplacement = -(1 - calendarScale) * 396; // 396 is the original height of the calendar

  const timeZoneName = useMemo(() => getGenericTimeZoneName(), []);

  const handleDateSelected = (date: dayjs.Dayjs | null) => {
    if (!date) return;
    onChangeDate(date.toDate());
    onChangeTimeSlot(null);
  };

  const handleTimeSlotSelected = (timeSlot: TimeSlotInterface) => {
    onChangeTimeSlot(timeSlot);
  };

  const handleShouldDisableDate = (day: dayjs.Dayjs) => {
    return !filteredDates.has(day.format('MM/DD/YYYY'));
  };

  return (
    <Stack
      justifyContent={'center'}
      gap={3}
      sx={{
        paddingBottom: '20px',
        width: '100%',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        alignItems: {
          xs: 'center',
          lg: 'flex-start',
        },
      }}
    >
      <Stack
        data-testid='datePicker'
        direction='column'
        sx={{
          height: {
            lg: '100%',
            xl: '100%',
            xs: 'auto',
            md: 'auto',
          },
          padding: '3px 0',
          width: {
            xs: '100%',
            sm: 'auto',
          },
          maxWidth: '100%',
          alignItems: 'center',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            maxDate={dayjs().add(30, 'day')}
            reduceAnimations
            disableHighlightToday
            disablePast
            slotProps={{
              actionBar: {
                actions: [],
              },
              toolbar: {
                hidden: true,
              },
              calendarHeader: {
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '.MuiPickersCalendarHeader-switchViewIcon': {
                    display: 'none',
                  },
                  '.MuiPickersCalendarHeader-labelContainer': {
                    flexDirection: 'inherit',
                  },
                },
              },
              switchViewIcon: {
                display: 'none !important',
              },
            }}
            sx={(theme) => ({
              '& .MuiDateCalendar-root': {
                height: 'auto',
                mb: 0,
              },
              borderRadius: '6px',

              boxShadow: '0px 2px 4px 0px rgba(8, 17, 34, 0.2)',
              '& .MuiDayCalendar-weekDayLabel': {
                margin: '0.1rem !important',
              },
              '& .MuiPickersDay-root:disabled': {
                backgroundColor: isLuxuryTheme
                  ? 'none'
                  : theme.palette.grey[200],
                color: theme.palette.grey[100],
                borderRadius: 0,
              },
              '& .MuiPickersDay-root:disabled:hover': {
                backgroundColor: theme.palette.grey[200],
              },
              '& .MuiPickersDay-root': {
                margin: '0.1rem !important',
                '&:focus': {
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.primary[300],
                  },
                },
                ':hover:not(:disabled)': {
                  color: 'inherit',
                },
              },
              '& .MuiButtonBase-root:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'auto',
                color: theme.palette.grey[100],
              },
              '& .MuiDayCalendar-slideTransition': {
                minHeight: 'auto !important',
              },
              '& .MuiDayCalendar-monthContainer': {
                position: 'relative !important',
              },
              width: 'fit-content',
              [theme.breakpoints.down('sm')]: {
                transform: `scale(${calendarScale})`,
                transformOrigin: 'top center',
                mb: `${verticalDisplacement}px`,
              },
            })}
            value={dayjs(selectedDate)}
            onChange={handleDateSelected}
            shouldDisableDate={handleShouldDisableDate}
          />
        </LocalizationProvider>
        <Stack
          direction='column'
          sx={{
            width: '100%',
          }}
        >
          <Typography variant='b1Bold' sx={{ textAlign: 'left', mt: 2 }}>
            Time zone
          </Typography>
          <Typography variant='b2Regular' sx={{ textAlign: 'left' }}>
            {timeZoneName}
          </Typography>
        </Stack>
      </Stack>
      <Box
        data-testid='timeSlotContainer'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
          width: {
            xs: '100%',
            sm: '295px',
          },
          maxWidth: { xs: '100%', lg: 'calc(100% - 24px - 391px)' },
          overflowY: 'scroll',
          flexWrap: 'nowrap',
          flexShrink: '0',
          maxHeight: {
            lg: '407px',
            xl: '407px',
            xs: 'none',
            md: 'none',
          },
          py: '3px',
          scrollbarColor: 'grey transparent',
        }}
      >
        {filteredSlots.map((timeSlot: TimeSlotInterface) => (
          <TimeSlot
            testId={timeSlot.startTime}
            key={timeSlot.startTime}
            onClickHandler={() => handleTimeSlotSelected(timeSlot)}
            label={dayjs(timeSlot.startTime).format('hh:mma').toString()}
            isSelected={selectedTimeSlot?.startTime === timeSlot.startTime}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default DateTimePicker;
