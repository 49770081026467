import { constants } from '@/constants';
import { getClubCode } from '@/lib/tagging';
import useAppointmentStore from '@/store/AppointmentStore';
import useThemeStore from '@/store/ThemeStore';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Confirmation } from '../utils/Confirmation';
import type { CreateAppointmentData } from '../utils/CreateAppointmentData';
import type { CustomerAccount } from '../utils/CustomerAccount';
import type { ProcessCustomerAccountData } from '../utils/ProcessCustomerAccountData';
import { postConfirmation } from './postConfirmation';
import { postProcessCustomerAccount } from './postProcessCustomerAccount';

type UseAppointmentCreationProps = {
  onStart?: () => void;
  onProcessCustomerAccountSuccess?: (data: CustomerAccount) => void;
  onProcessCustomerAccountError?: (err: Error) => void;
  onCreateAppointmentSuccess?: (data: Confirmation) => void;
  onCreateAppointmentError?: (err: Error) => void;
};

export const useAppointmentCreation = ({
  onStart,
  onProcessCustomerAccountSuccess,
  onProcessCustomerAccountError,
  onCreateAppointmentSuccess,
  onCreateAppointmentError,
}: UseAppointmentCreationProps) => {
  const {
    destinations,
    contactInfo,
    selectedDestination,
    selectedAgent,
    shouldUseDefaultWorktype,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    setCustomerId,
    setAccountId,
    setConfirmedAppointment,
    setAppointmentId,
    setConfirmationId,
  } = useAppointmentStore();

  const clubCode = useMemo(() => getClubCode(), []);

  const navigate = useNavigate();
  const { isLuxuryTheme } = useThemeStore();

  const luxuryDestination = useMemo(
    () =>
      destinations.find(
        (destination) => destination.name?.toLowerCase() === 'luxury',
      ),
    [destinations],
  );

  const processCustomerAccountMutation = useMutation({
    mutationFn: (data: ProcessCustomerAccountData) =>
      postProcessCustomerAccount(data),
    onSuccess: (data) => {
      onProcessCustomerAccountSuccess?.(data);

      const createAppointmentData: CreateAppointmentData = {
        workTypeId: shouldUseDefaultWorktype
          ? (luxuryDestination?.workTypeId ?? '')
          : (selectedDestination.workTypeId ?? ''),
        serviceTerritoryId: selectedAgent.serviceTerritoryId ?? '',
        schedStartTime: selectedStartTime ?? '',
        schedEndTime: selectedEndTime ?? '',
        appointmentType: contactInfo.communicationPreference as
          | 'Phone'
          | 'Video Conference',
        serviceResourceId: selectedAgent.resourceId ?? '',
        agentRecordId: selectedAgent.agentRecordId ?? '',
        firstName: contactInfo.firstName ?? '',
        lastName: contactInfo.lastName ?? '',
        phone: contactInfo.phoneNumber ?? '',
        email: contactInfo.email ?? '',
        zipCode: contactInfo.zipCode ?? '',
        typeOfTripDesired: contactInfo.typeOfTrip ?? [],
        whenDoYouPlanToTravel: contactInfo.expectedTravelTime ?? '',
        destinationsRequested: selectedDestination.destinationApiName ?? '',
        clubCode,
        additionalNotes: contactInfo.additionalInformation ?? '',
        accountId: data?.accountId ?? '',
        macsCustomerId: data?.customerId ?? '',
        isLuxury: isLuxuryTheme,
      };
      setCustomerId(data?.customerId);
      setAccountId(data?.accountId);
      createAppointmentMutation.mutate(createAppointmentData);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR) {
        onProcessCustomerAccountError?.(err);
      }
    },
  });

  const createAppointmentMutation = useMutation({
    mutationFn: (confirmationData: CreateAppointmentData) =>
      postConfirmation(confirmationData),
    onSuccess: (data) => {
      onCreateAppointmentSuccess?.(data);
      setConfirmedAppointment({
        agentInfo: selectedAgent,
        selectedDate,
        selectedStartTime,
        selectedEndTime,
      });
      setAppointmentId(data.appointmentId);
      setConfirmationId(data.confirmationId);
      setTimeout(() => {
        navigate('/confirmation', { replace: true });
      }, 250);
    },
    onError: (err) => {
      if (err.message !== constants.TANSTACK_ABORTED_ERROR) {
        onCreateAppointmentError?.(err);
      }
    },
  });

  const handleStartAppointmentCreation = useCallback(() => {
    onStart?.();
    const processCustomerAccountData: ProcessCustomerAccountData = {
      firstName: contactInfo.firstName ?? '',
      lastName: contactInfo.lastName ?? '',
      phone: contactInfo.phoneNumber ?? '',
      email: contactInfo.email ?? '',
      zipCode: contactInfo.zipCode ?? '',
      clubCode,
    };

    processCustomerAccountMutation.mutate(processCustomerAccountData);
  }, [
    contactInfo.firstName,
    contactInfo.lastName,
    contactInfo.phoneNumber,
    contactInfo.email,
    contactInfo.zipCode,
    clubCode,
    processCustomerAccountMutation.mutate,
    onStart,
  ]);

  return {
    isProcessCustomerAccountSuccess: processCustomerAccountMutation.isSuccess,
    isCreateAppointmentSuccess: createAppointmentMutation.isSuccess,
    handleStartAppointmentCreation,
  };
};
