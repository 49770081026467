// Region returned from Salesforce
export enum ApiRegion {
  Africa = 'Africa',
  Alaska = 'Alaska',
  Asia = 'Asia',
  EasternEurope = 'Europe: Eastern',
  AustraliaAndNewZealand = 'Australia & New Zealand',
  Caribbean = 'Caribbean',
  CentralAndSouthAmerica = 'Central & South America',
  CentralWesternEurope = 'Europe: Central & Western',
  Hawaii = 'Hawaii',
  ScandinaviaAndIceland = 'Europe: Northern Scandinavia & Iceland',
  SouthernEuropeAndMediterranean = 'Europe: Southern & Mediterranean',
  Mexico = 'Mexico',
  TahitiAndSouthPacific = 'South Pacific & Tahiti',
  UKAndIreland = 'Europe: United Kingdom & Ireland',
  USAAndCanada = 'United States & Canada',
  Other = 'Other',
}

// Region used on web to display labels
export enum Region {
  Africa = 'Africa',
  Alaska = 'Alaska',
  Asia = 'Asia',
  EasternEurope = 'Eastern Europe',
  AustraliaAndNewZealand = 'Australia & New Zealand',
  Caribbean = 'Caribbean',
  CentralAndSouthAmerica = 'Central & South America',
  CentralWesternEurope = 'Western & Central Europe',
  Hawaii = 'Hawaii',
  ScandinaviaAndIceland = 'Scandinavia & Iceland',
  SouthernEuropeAndMediterranean = 'Southern Europe & Mediterranean',
  Mexico = 'Mexico',
  TahitiAndSouthPacific = 'South Pacific & Tahiti',
  UKAndIreland = 'UK & Ireland',
  USAAndCanada = 'Mainland U.S. & Canada',
  Other = 'Other',
}
